<template>
  <v-dialog v-model="dialog" max-width="770" max-height="700" @click:outside="closeVideoDialog"
    @keydown.esc="closeVideoDialog">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeVideoDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row v-if="videoUrl">
              <v-col>
                <video ref="videoPlayer" width="436" height="374" controls controlsList="nodownload" @play.stop="onPlay">
                  <source :src="videoUrl" />
                </video>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex align-center">
                <v-btn v-if="!loading" class="pa-0" @click.prevent="downloadVideo(item, true)" color="gray3 " text>
                  <v-icon>mdi-download</v-icon>
                  Download
                </v-btn>
                <progress class="ml-auto" v-if="loading" :max="total" :value="loaded"></progress>
                <span class="mx-2" v-if="loading">{{ percentage }}%</span>
                <div style="max-width: 250px; width: 100%" class="mx-2">
                  <v-select :background-color="$vuetify.theme.dark ? 'input_color' : '#EEEEEE'
                    " v-model="id_praca" :items="pracaOptions" item-text="name" item-value="id" label="Exportar para"
                    dense filled color="primary" />
                </div>
                <v-btn @click.prevent="exportarVideo(item, id_praca)" slot="end" text class="pa-0 ml-auto" min-width="0">
                  <v-icon color="primary" class="pr-1">mdi-send</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex">
                <h4 class="mr-2 text_primary--text">CATEGORIAS:</h4>
                <div class="wrapper">
                  <span class="categorias text-capitalize" v-for="categoria in categorias" :key="categoria.id"
                    @click.prevent="filterByCategoria(categoria.id)">
                    {{ categoria.nome | capitalize }}
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex">
                <h4 class="mr-2 text_primary--text">TAGS:</h4>
                <div class="wrapper">
                  <span @click.prevent="filterByTag(tag)" class="tags" v-for="tag in tags" :key="tag.id">
                    {{ tag.nome | capitalize }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="d-flex flex-column" v-if="videos_relacionados">
            <div :style="{
              backgroundColor: $vuetify.theme.dark ? '#2B2625' : '#FFFFFF',
              marginRight: '-24px',
              borderLeft: '3px solid var(--v-primary-base)',
            }">
              <h4 class="ml-4">Vídeos Relacionados</h4>
            </div>
            <div v-for="video in videos_relacionados" :key="video.id" class="d-flex mt-3 rounded"
              style="position: relative">
              <v-img class="rounded text-right" width="140" height="103" v-if="video.thumb_url" :src="video.thumb_url"
                @click="openVideoRelacionadoDialog(video)">
                <v-btn v-if="video.thumb_url" color="#EEEEEE" rounded small class="ma-2 font-weight-bold">
                  {{ video?.duracao ? video.duracao.substring(0, 8) : null }}
                </v-btn>
              </v-img>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <VideoRelacionado :item="video_relacionado" ref="videoRelacionado" :exportarVideo="exportarVideo"
      :fetchData="fetchData" :incrementarVisualizacao="incrementarVisualizacao"
      :incrementarDownloadVisualizacao="incrementarDownloadVisualizacao" />
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import VideoRelacionado from "./VideoRelacionado.vue";
import axios from "axios";

export default {
  name: "VideoSelecionado",
  components: { VideoRelacionado },
  props: {
    item: Object,
    videos_relacionados: Array,
    categorias: Array,
    tags: Array,
    videoUrl: String,
    exportarVideo: Function,
    fetchData: Function,
    changeEditModel: Function,
    incrementarVisualizacao: Function,
    incrementarDownloadVisualizacao: Function
  },
  data() {
    return {
      dialog: false,
      urlVideo: null,
      video_relacionado: null,
      pracaOptions: [
        { id: 1, name: "PAMRJ-MAG" },
        { id: 2, name: "PAMRJ-HN" },
        { id: 3, name: "PAM-BSB" },
        { id: 4, name: "PAM-SP" },
        { id: 5, name: "PAM-BH" },
        { id: 6, name: "VIZ-ESPORTE" },
        
      ],
      id_praca: null,
      loading: false,
      loaded: 0,
      total: 0,
    };
  },
  methods: {
    async downloadVideo(item, update = true) {
      try {
        if (item.nrDownload > item.maxDownload) {
          return this.$toast.info(
            "O número de downloads permitidos para esse vídeo já encerrou."
          );
        }

        const instance = axios.create();

        instance.defaults.onDownloadProgress = progressEvent => {
          this.loaded = progressEvent.loaded;
          this.total = progressEvent.total;
        };

        this.loading = true;
        await instance({
          url: item.video_url_download,
          method: "GET",
          responseType: "blob",

        })
          .then((response) => {
            let nome_download
            const href = URL.createObjectURL(response.data);
            const link = document.createElement("a");

            if (item.nome.includes('.mxf')) {
              nome_download = item.nome
            } else {
              nome_download = `${item.nome}.mxf`
            }

            console.log(nome_download)

            link.href = href;
            link.download = `${nome_download}`;
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(href);
          })
          .then(async () => {
            await this.incrementarDownloadVisualizacao(item, update);
          });
      } catch (error) {
        this.$toast.error(
          "Não foi possível iniciar o download, tente novamente."
        );
      } finally {
        this.loading = false;
      }
    },
    async onPlay() {
      try {
        if (this.$refs.videoPlayer.seeking) {
          console.log(this.$refs.videoPlayer.seeking, 'retornou')
          return;
        }
        await this.incrementarVisualizacao(this.item, true)
      } catch (error) {
        console.log(error)
      }
    },
    async filterByTag(tag) {
      this.dialog = false
      this.changeEditModel([tag])
    },
    async filterByCategoria(id_categoria) {
      this.dialog = false;

      await this.setFiltroCategoriaSubcategoria({
        id_categoria,
        id_subcategoria: null,
      });

      await this.fetchData()
    },
    ...mapActions({
      setFiltroCategoriaSubcategoria:
        "conteudos/setFiltroCategoriaSubcategoria",
    }),
    openVideoRelacionadoDialog(objeto) {
      this.video_relacionado = objeto;
      this.$refs.videoRelacionado.openVideoRelacionadoDialog();
    },
    openVideoDialog() {
      this.dialog = true;
    },
    closeVideoDialog() {
      this.dialog = false;
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.currentTime = 0;
      }
    },
  },
  filters: {
    capitalize(string) {
      const newString = string
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return newString;
    },
  },
  computed: {
    percentage() {
      if (this.total === 0) {
        return 0;
      } else {
        return Math.round((this.loaded / this.total) * 100);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
progress::-moz-progress-bar {
  background: var(--v-primary-base);
}

progress::-webkit-progress-value {
  background: var(--v-primary-base);
}

progress {
  color: var(--v-primary-base);
}

.categorias {
  color: var(--v-text_secondary-base);
  cursor: pointer;

  &:hover {
    color: var(--v-primary-base)
  }
}

.categorias+.categorias {
  margin-left: 0.2rem;
}

.categorias:not(:last-child):after {
  content: ", ";
}

.tags {
  color: var(--v-text_secondary-base);
  cursor: pointer;

  &:hover {
    color: var(--v-primary-base)
  }
}

.tags+.tags {
  margin-left: 0.2rem;
}

.tags:not(:last-child):after {
  margin-left: -3px;
  content: ", ";
}

.wrapper {
  width: 300px;
}
</style>