<template>
  <div class="text-right">
    <v-dialog v-model="dialog" max-width="400" max-height="270">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="addSubcategoriaButton"
          v-bind="attrs"
          v-on="on"
          v-if="adicionar"
        >
          Adicionar subcategoria
        </v-btn>
        <v-btn text v-bind="attrs" v-on="on" v-else>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="mb-8">
          {{ adicionar ? "Adicionar subcategoria" : "Editar subcategoria" }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center">
          <v-form ref="form" lazy-validation style="width: 100%">
            <v-text-field
              background-color="input_color"
              filled
              label="Nome"
              color="input_color"
              v-model="nome"
              class="mb-8"
            >
            </v-text-field>

            <!-- Adicionar -->

            <v-select
              v-if="adicionar && categorias"
              background-color="input_color"
              filled
              :items="categorias"
              item-text="nome"
              label="Categorias"
              v-model="categoria"
              return-object
            ></v-select>

            <!-- Editar -->

            <v-select
              v-else-if="categorias"
              background-color="input_color"
              filled
              :items="categorias"
              item-value="id"
              item-text="nome"
              label="Categorias"
              v-model="categoria"
              return-object
            ></v-select>

            <div class="buttonsWrapper mt-10">
              <v-btn @click.prevent="dialog = false" class="mr-8" text>
                Cancelar
              </v-btn>
              <v-btn
                class="addButton"
                @click.prevent="
                  adicionar ? inserirSubCategoria() : editarSubCategoria()
                "
              >
                {{ adicionar ? "Adicionar" : "Salvar" }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

 
<script>
import { apiClient } from "@/services/api";
import { mapActions } from "vuex";

import store from "@/store";

export default {
  props: {
    adicionar: { type: Boolean, required: false },
    categorias: { type: Array, required: true },
    editarData: { type: Object, required: false },
  },
  data() {
    return {
      nome: "",
      categoria: null,
      dialog: false,
    };
  },
  mounted() {
    if (this.editarData) {
      this.nome = this.editarData.nome;
      this.categoria = this.editarData.idCategoria;
    }
  },
  methods: {
    ...mapActions({
      getSubCategorias: "subcategorias/getSubCategorias",
    }),
    async inserirSubCategoria() {
      try {
        const response = await apiClient.post("/subcategoria", {
          nome: this.nome.toUpperCase(),
          idCategoria: this.categoria.id,
          criadoEm: new Date(),
          criadoPor: store.state.users.user.id,
          alteradoPor: store.state.users.user.id,
          alteradoEm: new Date(),
          status: 1,
        });

        if (response.status === 200) {
          await this.getSubCategorias({
            categoria_id: null,
            nome: "",
            pagina: 1,
            itensPorPagina: 1000,
          });
        }
      } catch (error) {
        return alert(error.message);
      } finally {
        this.nome = "";
        this.dialog = false;
        this.categoria = null;
      }
    },

    async editarSubCategoria() {
      try {
        const requestBody = {
          id: this.editarData.id,
          nome: this.nome.toUpperCase(),
          idCategoria: this.categoria.id || this.editarData.idCategoria,
          criadoEm: this.editarData.criadoEm,
          criadoPor: this.editarData.criadoPor,
          alteradoPor: store.state.users.user.id,
          alteradoEm: new Date(),
          status: 1,
        };

        const response = await apiClient.put("/subcategoria", requestBody);

        if (response.status === 200) {
          await this.fetchData();
        }
      } catch (error) {
        return alert(error.message);
      } finally {
        this.dialog = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addSubcategoriaButton {
  background-color: var(--v-primary-base) !important;
  color: var(--v-gray4-base);
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .v-btn {
    width: 110px;
    height: 36px;
  }
}

.addButton {
  background: var(--v-primary-base) !important;
  color: var(--v-gray4-base);
}
</style>