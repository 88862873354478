<template>
  <v-dialog v-model="dialog" width="796" height="701" @click:outside="closeDetailDialog" @keydown.esc="closeDetailDialog">
    <v-card v-if="conteudo">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDetailDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <video ref="videoPlayer" v-if="conteudo" width="320" height="240" controls controlsList="nodownload"
                  @play="onPlay">
                  <source :src="conteudo.video_low_url" type="video/mp4" />
                </video>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex">
                <h4 class="mr-2 text_primary--text">CATEGORIAS:</h4>
                <div class="wrapper">
                  <span class="categorias" v-for="categoria in categorias" :key="categoria.id">
                    {{ categoria.nome | capitalize }}
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex">
                <h4 class="mr-2 text_primary--text">TAGS:</h4>
                <div class="wrapper">
                  <span class="tags" v-for="tag in tags" :key="tag.id">
                    {{ tag.nome | capitalize }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <div class="pa-3" style="background: var(--v-secondary2-base)">
              <div class="d-flex">
                <h3 class="mr-2">Criador por:</h3>
                <span color="#FFFFFF">
                  {{ conteudo.nomeUsuario }}
                </span>
              </div>
              <div class="d-flex">
                <h3 class="mr-2">Data:</h3>
                <span color="#FFFFFF">
                  {{
                    Intl.DateTimeFormat("pt-Br").format(
                      new Date(conteudo.criadoEm)
                    )
                  }}
                </span>
              </div>

              <div class="d-flex">
                <h3 class="mr-2">Última alteração:</h3>
                <span color="#FFFFFF">
                  {{
                    Intl.DateTimeFormat("pt-Br").format(
                      new Date(conteudo.alteradoEm)
                    )
                  }}
                </span>
              </div>

              <v-divider class="mt-4 mb-4"></v-divider>

              <h3 class="mt-2">QUANTIDADE MÁXIMA DE DOWNLOADS:</h3>
              <span>{{ conteudo.maxDownload }}</span>
              <h3 class="mt-2">NÚMERO DE DOWNLOADS:</h3>
              <span>{{ conteudo.nrDownload }}</span>
              <h3 class="mt-2">LIMITE DE ARMAZENAMENTO:</h3>
              <span>{{
                Intl.DateTimeFormat("pt-Br").format(
                  new Date(conteudo.limiteArmazenamento)
                )
              }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

 
<script>
export default {
  props: {
    conteudo: Object,
    categorias: Array,
    tags: Array,
  },
  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    openDetalhesConteudoDialog() {
      this.dialog = true;
    },
    closeDetailDialog() {
      this.dialog = false;
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.currentTime = 0;
      }
    },
    async onPlay() {
      await this.incrementarVisualizacao(this.item, true)
    },
  },
  filters: {
    capitalize(string) {
      const newString = string
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      return newString;
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: var(--v-gray-base);
  font-weight: normal;
}

.categorias {
  color: var(--v-text_secondary-base);
}

.categorias+.categorias {
  margin-left: 0.2rem;
}

.categorias:not(:last-child):after {
  content: ", ";
}

.tags {
  color: var(--v-text_secondary-base);
}

.tags+.tags {
  margin-left: 0.2rem;
}

.tags:not(:last-child):after {
  margin-left: -3px;
  content: ", ";
}

.wrapper {
  width: 300px;
}
</style>