<template>
  <div>
    <v-row class="searchWrapper">
      <v-col cols="12" sm="8" md="4">
        <TagsCombobox v-model="tagsInput" :items="tags" :editModel="editModel" :label="'Busque por tags'"
          @change="updateTagsObject" :editar="true" />
      </v-col>
      <v-col class="text-right">
        <AddConteudo />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-select v-if="categorias" :items="[{ nome: 'TODAS', id: null }, ...categorias.lstResultado]" item-text="nome"
          item-value="id" label="Categorias" filled v-model="categoria_id" @input="onChange"
          background-color="input_color"></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select v-if="subcategorias" :items="[{ nome: 'TODAS', id: null }, ...subcategorias.lstResultado]"
          item-text="nome" item-value="id" label="Subcategorias" filled v-model="subcategoria_id" @input="onChange"
          class="ml-2" background-color="input_color"></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table v-if="conteudo" :headers="headers" :items="conteudo" class="elevation-1 input_color mt-8"
          :loading="loading" :header-props="headerProps" hide-default-footer :items-per-page="itensPorPagina">
          <template v-slot:item="row">
            <tr :key="row.item.id">
              <td>{{ row.item.nrDownload }}</td>
              <td class="thumbId" @click="openDetalhesConteudoDialog(row.item)">
                <v-img contain width="100" height="100" :src="row.item.thumb_url" />
              </td>
              <td>{{ Number(row.item.status) === 1 ? "Ativo" : "Inativo" }}</td>
              <td>
                {{
                  Intl.DateTimeFormat("pt-Br").format(
                    new Date(row.item.limiteArmazenamento)
                  )
                }}
              </td>
              <td class="d-flex align-center justify-end pa-0">
                <v-divider vertical></v-divider>
                <EditarConteudo :item="row.item" :key="row.item.id" :searchField="search" :categoria_id="categoria_id"
                  :subcategoria_id="subcategoria_id" />
                <v-divider vertical></v-divider>
                <RemoverConteudo :id="row.item.id" />
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination class="mt-5" v-model="conteudoPage" :length="totalPages" @input="handlePageChange"
          :total-visible="8" />
      </v-col>
    </v-row>
    <VisualizarConteudo :incrementarVisualizacao="incrementarVisualizacao" ref="editarDialog" :conteudo="modalData"
      :tags="tagsConteudo" :categorias="conteudo_categorias" />
  </div>
</template>
<script>
import VisualizarConteudo from "@/components/Modals/Conteudo/VisualizarConteudo.vue";
import EditarConteudo from "@/components/Modals/Conteudo/EditarConteudo.vue";
import RemoverConteudo from "@/components/Modals/Conteudo/RemoverConteudo.vue";
import AddConteudo from "@/components/Modals/Conteudo/AddConteudo.vue";

import { mapActions, mapGetters } from "vuex";
import { apiClient, apiClientConversao } from "@/services/api";
import TagsCombobox from "@/components/Modals/Conteudo/TagsCombobox.vue";

export default {
  name: "ConteudoView",
  components: {
    VisualizarConteudo,
    RemoverConteudo,
    EditarConteudo,
    AddConteudo,
    TagsCombobox
  },
  data() {
    return {
      tag: "",
      editar_conteudo_dialog: false,
      search: "",
      categoria_id: null,
      maxItems: 1000,
      praca_id: null,
      subcategoria_id: null,
      status: null,
      loading: false,
      pagina: 1,
      conteudoPage: 1,
      itensPorPagina: 10,
      headers: [
        {
          text: "Downloads",
          align: "start",
          sortable: false,
        },
        {
          text: "Thumbnail",
          align: "start",
          sortable: false,
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
        },
        {
          text: "Armazenamento",
          align: "start",
          sortable: false,
        },
        {
          text: "Ações",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ],
      headerProps: {
        mobile: true,
        checkboxColor: "success--text",
      },
      debounce: null,
      statusOptions: [
        { id: 1, status: "Ativo" },
        { id: 2, status: "Desativado" },
      ],
      pracaOptions: [
        { id: 1, name: "PAMRJ-MAG" },
        { id: 2, name: "PAMRJ-HN" },
        { id: 3, name: "PAM-BSB" },
        { id: 4, name: "PAM-SP" },
        { id: 5, name: "PAM-BH" },
        { id: 6, name: "VIZ-ESPORTE" },
        
      ],
      modalData: null,
      conteudo_categorias: null,
      tagsInput: null,
      tagsObj: [],
      editModel: null,
      tagsConteudo: null,
      debounceTag: null,
    };
  },
  async created() {
    await this.fetchData();

    await this.getCategorias({
      nome: "",
      pagina: 1,
      itensPorPagina: 1000,
    });
    await this.getSubCategorias({
      nome: "",
      categoria_id: this.categoria_id,
      pagina: 1,
      itensPorPagina: 1000,
    });
  },
  computed: {
    ...mapGetters({
      conteudo: "conteudos/getterConteudos",
      categorias: "categorias/getterCategorias",
      subcategorias: "subcategorias/getterSubcategorias",
      totalConteudos: "conteudos/getterNumConteudos",
      tags: "tags/getterTags",
      lastDownload: "conteudos/getterLastDownload"
    }),

    totalPages() {
      let pages = null;
      if (this.conteudo) {
        pages = Math.ceil(this.totalConteudos / this.itensPorPagina);
      }
      return pages;
    },
  },
  methods: {
    async incrementarVisualizacao(item, update) {
      try {
        const seconds = new Date().getTime() / 1000 //Tempo da tada de hoje convertido em segundos

        if(this.lastDownload && (seconds - this.lastDownload) < 30) {
          return ;
        }

        item.nrVisualizacao += 1;
        item.alteradoEm = new Date();
        item.alteradoPor = this.$store.state.users.user.id
        item.LoginUsuarioLogado = this.$store.state.users.user.id

        console.log(item)

        await apiClient.put(`/conteudo/updatewhensee`, item).then(async (response) => {
          if (response.status === 200 && update) {
            await this.fetchData();
            this.setLastDownload(new Date())
          }
        });
      } catch (error) {
        return error;
      }
    },
    async updateTagsObject(values) {
      this.tagsObj = []

      values.map(value => {
        this.tagsObj.push(value.nome)
      })

      await this.onInput()
    },
    onInput() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {

        const promise = this.tagsObj.map(tag =>
          apiClient.post("/palavrasbuscadas", {
            data: new Date().toISOString(),
            palavra: tag,
          })
        )

        await Promise.all(promise).then(async () => {

          await this.fetchData();
        })


      }, 1500);
    },
    ...mapActions({
      getConteudos: "conteudos/getConteudos",
      getCategorias: "categorias/getCategorias",
      getSubCategorias: "subcategorias/getSubCategorias",
      setFiltroTag: "conteudos/setFiltroTag",
      getTags: "tags/getTags",
      setLastDownload: "conteudos/lastDownload"
    }),
    async fetchData() {
      try {
        this.loading = true;
        await this.getConteudos({
          tag: this.tagsObj,
          categoria: this.categoria_id,
          subcategoria: this.subcategoria_id,
          status: this.status,
          praca: this.praca_id,
          pagina: this.conteudoPage,
          itensPorPagina: this.itensPorPagina,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async openDetalhesConteudoDialog(item) {
      const response = await apiClientConversao.get(
        `/video/getsignedurlvisualizacao?idVideo=${item.videoId}`
      );

      const { data } = await apiClient.get(`conteudo/getbyid?id=${item.id}`)
      this.$refs.editarDialog.openDetalhesConteudoDialog();
      await this.getConteudoTags(item.id);
      await this.getConteudoCategorias(item.id);

      this.modalData = data;
      this.modalData.video_low_url = response.data;
    },
    onChange() {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(async () => {
        await this.fetchData();
      }, 1500);
    },
    async handlePageChange() {
      await this.fetchData();
      window.scrollTo({ top: 0, behavior: "smooth" });

    },
    async getConteudoTags(conteudo) {
      try {
        const response = await apiClient.get(
          `/tag/gettagbyidconteudo?idConteudo=${conteudo}`
        );
        this.tagsConteudo = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getConteudoCategorias(conteudo) {
      try {
        const response = await apiClient.get(
          `/categoria/getcategoriasbyidconteudo?idConteudo=${conteudo}`
        );
        this.conteudo_categorias = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    async tagsInput() {
      await this.getTags({
        nome: this.tagsInput || "",
        pagina: 1,
        itensPorPagina: 100,
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.thumbId:hover {
  cursor: pointer;
}

tr {
  td {
    height: 101px !important;
  }
}
</style>