<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <div class="header">
          <button style="margin-left: auto" type="button" class="btn-close" @click="closeMidiaModal"
            aria-label="Close modal">
            <CloseButton />
          </button>
        </div>

        <div class="body">
          <div class="interrogacao">
            <Interrogacao />
          </div>

          <h2>Instruções</h2>

          <p>
            Para acessar o sistema, clique no botão e utilize seu email e senha
            corporativos. Caso precise de ajuda, entre em contato com o Help
            Desk.
          </p>

          <p>
            Ligue para <strong>3911</strong> se estiver na Globo. <br />Se
            estiver fora, ligue para <strong>(21)3004-3911</strong>
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>
  
<script>
import CloseButton from "../../assets/close.svg";
import Interrogacao from "../../assets/interrogacao.svg";
export default {
  components: { CloseButton, Interrogacao },
  methods: {
    closeMidiaModal() {
      this.$emit("close");
    },
  },
};
</script>
  
<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal {
  background: #ffffff;
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  width: 31rem;
  height: 32.5rem;
}

div.header {
  align-content: center;
  display: flex;
  height: 2rem;
  padding: 1rem;

  button {
    margin-left: auto;
    background-color: transparent;
  }
}

div.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .interrogacao {
    vertical-align: middle;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green-primary);
  }

  h2 {
    margin-top: 1rem;
    margin-bottom: 3rem;
    color: var(--v-secondary-base);
    font-weight: 700;
    font-size: 1rem;
  }

  p {
    width: 304px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 22.4px;
    color: var(--v-secondary-base);
    text-align: center;
  }

  p+p {
    margin-top: 4rem;
  }

  strong {
    color: var(--v-primary-base);
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
  