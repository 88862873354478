import Vue from 'vue'
import Vuex from 'vuex'
import categorias from './modules/categoria.module'
import subcategorias from './modules/subcategoria.module';
import users from './modules/users.module'
import log from './modules/log.module'
import relatorios from './modules/relatorios.module';
import parametros from './modules/parametro.module';
import conteudos from './modules/conteudos.module'
import auth from './modules/auth.module'
import tags from './modules/tags.module';
import users_table from './modules/users_table.module';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    categorias,
    subcategorias,
    users,
    log,
    relatorios,
    parametros,
    conteudos,
    tags,
    auth,
    users_table
  },
  plugins: [createPersistedState({ paths: ["users"] })],
})
