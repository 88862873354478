var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"px-12",attrs:{"color":"app_bar_color","app":"","clipped-left":"","height":"100px"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/"}},[_c('Logo')],1)],1),_c('div',{staticClass:"d-flex align-center ml-auto"},[_c('FaleConosco'),_c('AppProfile',{attrs:{"url":_vm.url}})],1)],1),_c('v-navigation-drawer',{staticClass:"navDrawer",attrs:{"color":"app_bar_color","app":"","clipped":"","width":"300"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.url === '/')?_c('v-list',{staticClass:"pa-12",attrs:{"dense":""}},_vm._l((_vm.categoriasEditada),function(categoria){return _c('v-list-group',{key:categoria.name,attrs:{"append-icon":categoria.name !== 'Todas' ? 'mdi-chevron-down' : null,"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.filtraConteudo(categoria.id, null)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{class:categoria.active
              ? 'text-subtitle-1'
              : 'text-subtitle-1 text_secondary--text'},[_vm._v(" "+_vm._s(_vm._f("capitalize")(categoria.name))+" ")])],1)]},proxy:true}],null,true),model:{value:(categoria.active),callback:function ($$v) {_vm.$set(categoria, "active", $$v)},expression:"categoria.active"}},_vm._l((categoria.subcategorias),function(subcategoria){return _c('v-list-item',{key:subcategoria.id_subcategoria,class:_vm.filtros.subcategoria_id === subcategoria.id_subcategoria
  ? 'subcategoriaList active'
  : 'subcategoriaList ',on:{"click":function($event){$event.preventDefault();return _vm.filtraConteudo(
            subcategoria.id_categoria,
            subcategoria.id_subcategoria
          )}}},[_c('v-list-item-content',{staticClass:"pl-2"},[(subcategoria.name.length > 12)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({staticStyle:{"font-size":"16px"}},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("capitalize")(subcategoria.name))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(subcategoria.name))])])]:[_c('v-list-item-title',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(subcategoria.name))+" ")])]],2)],1)}),1)}),1):_vm._e(),(_vm.url.includes('/admin'))?_c('v-list',{staticClass:"pa-16",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.adminArea),function(item){return _c('v-list-item',{key:item.title,staticClass:"adminArea",attrs:{"to":item.url}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.title))+" ")])],1)],1)}),1)],1):_vm._e(),_c('v-divider'),_c('v-list',{staticClass:"px-11"},[_c('v-list-item',[_c('v-switch',{staticClass:"ma-0",attrs:{"label":_vm.$vuetify.theme.dark ? 'Modo Claro' : 'Modo Escuro'},on:{"change":_vm.setTheme},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1)],1),_c('v-divider')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }