import axios from "axios";
import store from "../../store";

// axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

const URL = process.env.VUE_APP_API_ENDPOINT;
const URLConversao = process.env.VUE_APP_API_ENDPOINT_CONVERSAO;

export const apiClient = axios.create({
  baseURL: URL,
});

export const apiClientConversao = axios.create({
  baseURL: URLConversao,
});

const responseSuccessHandler = (response) => {
  return response;
};

const responseErrorHandler = (error) => {
  if (error.response.status === 401) {
    store.dispatch("users/logout");
  }

  return Promise.reject(error);
};

apiClient.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

apiClientConversao.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);
