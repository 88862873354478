import { apiClient, apiClientConversao } from '@/services/api'

const state = () => ({
  conteudos: null,
  numConteudos: null,
  pagina: 1,
  filtros: {
    tag: "",
    categoria_id: null,
    subcategoria_id: null,
    status: null,
    praca_id: null,
    pagina: 1,
    itensPorPagina: 40,
  },
  lastDownload: JSON.parse(localStorage.getItem('@poupatempo/lastDownload')) || null
});

const getters = {
  getterConteudos: state => {
    return state.conteudos;
  },
  getterNumConteudos: state => {
    return state.numConteudos;
  },
  getterFiltrosConteudo: state => {
    return state.filtros
  },
  getterLastDownload: state => {
    if(state.lastDownload) {
      return state.lastDownload.getTime() / 1000
    }
    return state.lastDownload
  }
}

const actions = {
  async getConteudos({ commit }, payload) {
    const { pagina, itensPorPagina, categoria, subcategoria, tag } = payload
    let tagQS
    if (tag.length > 0) {
      tagQS = tag.map(t => `&ListTag=${encodeURIComponent(t)}`)
      tagQS = tagQS.join(',', "").replaceAll(',', "")
    } else {
      tagQS = ""
    }

    let conteudos

    const urlParams = `${categoria !== null ? `&IdCategoria=${categoria}` : ''}${subcategoria !== null ? `&IdSubcategoria=${subcategoria}` : ''}${tagQS}`

    await apiClient.get(`conteudo?Pagina=${pagina}&ItensPorPagina=${itensPorPagina}&OrderBy=criadoEm desc${urlParams}`)
      .then(async (response) => {
        commit('setNumConteudos', response.data.total);
        const urls_download = await apiClientConversao.post(`/video/getlistsignedurldownload`, response.data.lstResultado)
        const urls_thumb = await apiClientConversao.post(`/video/getlistsignedurlthumb`, response.data.lstResultado)

        if (urls_download.status === 200 && urls_thumb.status === 200) {
          conteudos = response.data.lstResultado.map(conteudo => {
            const video_url = urls_download.data.filter(result => result.idConteudo === conteudo.id)
            const thumb_url = urls_thumb.data.filter(result => result.idConteudo === conteudo.id)

            return {
              pracaId: conteudo.pracaId,
              maxDownload: conteudo.maxDownload,
              pesquisaRRD: conteudo.pesquisaRRD,
              videoId: conteudo.videoId,
              thumbId: conteudo.thumbId,
              limiteArmazenamento: conteudo.limiteArmazenamento,
              expurgado: conteudo.expurgado,
              nrVisualizacao: conteudo.nrVisualizacao,
              nrDownload: conteudo.nrDownload,
              duracao: conteudo.duracao,
              criadoPor: conteudo.criadoPor,
              criadoEm: conteudo.criadoEm,
              alteradoPor: conteudo.alteradoPor,
              alteradoEm: conteudo.alteradoEm,
              id: conteudo.id,
              status: conteudo.status,
              video_url_download: video_url[0].urlSigned,
              thumb_url: thumb_url[0].urlSigned,
              nome: video_url[0].nomeVideo
            }
          })
        }
      })
      .then(() => {
        commit("setConteudos", conteudos);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async setPaginaConteudo({ commit }, payload) {
    commit("setPagina", payload);
  },
  async setFiltroCategoriaSubcategoria({ commit }, payload) {
    commit("setFiltroCategoriaSubcategoria", payload);
  },
  async setFiltroTag({ commit }, payload) {

    commit("setFiltroTag", payload)
  },
  async lastDownload({commit}, payload) {
    localStorage.setItem('@poupatempo/lastDownload', JSON.stringify(payload))
    commit('setLastDownload', payload)
  }
};

const mutations = {
  setConteudos(state, payload) {
    state.conteudos = payload;
  },
  setNumConteudos(state, payload) {
    state.numConteudos = payload;
  },
  setPagina(state, payload) {
    state.filtros.pagina = payload
  },
  setFiltroCategoriaSubcategoria(state, payload) {
    state.filtros.categoria_id = payload.id_categoria
    state.filtros.subcategoria_id = payload.id_subcategoria
  },
  setFiltroTag(state, payload) {
    state.filtros.tag = payload
  },
  setLastDownload(state, payload) {
    state.lastDownload = payload
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
