<template>
  <div>
    <v-row>
      <v-col class="d-flex align-center">
        <span>Perfil:</span>
        <v-select v-if="perfis" :items="[{ id: 0, nome: 'Todos' }, ...perfis]" v-model="alcadaId" filled
          background-color="input_color" item-color="var(--v-gray3-base)" class="perfilSelect mx-2" item-value="id"
          item-text="nome" @change="onInput" />
      </v-col>

      <v-col class="text-right">
        <AdicionarAprovador :alcadaId="alcadaId" :fetchData="fetchData" />
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-col>
        <v-data-table v-if="aprovadores" :headers="headers" :items="aprovadores" :items-per-page="itensPorPagina"
          class="elevation-1 secondary mt-8" :loading="loading" :header-props="headerProps" hide-default-footer
          :footer-props="footerProps">
          <template v-slot:item="row">
            <tr :key="row.item.id">
              <td>{{ row.item.nomeUsuario }}</td>
              <td class="d-flex align-center justify-end pa-0">
                <RemoverAprovador :removerAprovador="removerAprovador" :id="row.item.id" :key="row.item.id"
                  :nomeAprovador="row.item.nomeUsuario" :fetchData="fetchData" />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AdicionarAprovador from "@/components/Modals/Aprovador/AdicionarAprovador.vue";
import RemoverAprovador from "@/components/Modals/Aprovador/RemoverAprovador.vue";
import { apiClient } from "@/services/api";

export default {
  components: { AdicionarAprovador, RemoverAprovador },
  async created() {
    await this.fetchData();
    await this.listPerfis()
  },
  data() {
    return {
      loading: false,
      pagina: 1,
      alcadaId: 1,
      itensPorPagina: 10,
      headers: [
        {
          text: "Aprovador",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Ações",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ],
      footerProps: {
        itemsPerPageText: "Linhas por página",
        itemsPerPageAllText: "Tudo",
        pageText: "{0}-{1} de {2}",
      },
      headerProps: {
        mobile: true,
        checkboxColor: "success--text",
      },
      aprovadores: null,
      perfis: null,
      debounce: null
    };
  },
  computed: {
    totalPages() {
      let pages = null;
      if (this.aprovadores) {
        pages = Math.ceil(this.items.total / this.itensPorPagina);
      }
      return pages;
    },
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const response = await apiClient.get(`/aprovadorperfil?filtroIdAlcada=${this.alcadaId}`);

        this.aprovadores = response.data;

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

    },
    async listPerfis() {
      try {
        const response = await apiClient.get(`/usuario/listarperfispoupatempo`);

        this.perfis = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async handlePageChange() {
      await this.fetchData();
    },
    async removerAprovador(id) {
      try {
        const response = await apiClient.delete(`/aprovadorperfil/${id}`);
        if (response.status === 200) {
          await this.fetchData();
        }
      } catch (error) {
        this.$toast.error("Não foi possível excluir usuário aprovador.");
      }
    },
    onInput() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.fetchData();
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.perfilSelect {
  width: 320px;
  height: 100%;
  align-self: center;
}

.perfilSelect.v-text-field>.v-input__control>.v-input__slot:before {
  border-style: none;
  color: var(--v-gray3-base) !important;
}

.perfilSelect.v-text-field>.v-input__control>.v-input__slot:after {
  border-style: none;
  color: var(--v-gray3-base) !important;
}

.v-text-field__details {
  display: none;
}
</style>