<template>
  <v-card class="card">
    <v-img :src="item.thumb_url" height="200" class="text-right thumbImg" @click="openVideoDialog()">
      <v-btn color="#EEEEEE" rounded small class="ma-2 font-weight-bold">
        {{ duracao }}
      </v-btn>
      <v-card-subtitle class="cardSubtitle d-flex align-center justify-end">
        <span color="text_secondary" class="mr-4 d-flex align-center">
          {{ item.nrVisualizacao }}
          <v-icon color="#9e9e9e" class="pl-1">mdi-eye</v-icon>
        </span>
        <span color="text_secondary" class="d-flex align-center">
          {{ item.nrDownload }}
          <v-icon color="#9e9e9e" class="pl-1"> mdi-download </v-icon>
        </span>
      </v-card-subtitle>
    </v-img>

    <v-card-text class="d-flex align-center">
      <div style="max-width: 280px; width: 100%; margin-right: 1rem">
        <v-select :background-color="$vuetify.theme.dark ? 'input_color' : '#EEEEEE'" v-model="id_praca"
          :items="pracaOptions" item-text="name" item-value="id" label="Exportar para" dense filled color="primary" />
      </div>
      <v-btn @click.prevent="exportarVideo(item, id_praca)" slot="end" text class="pa-0 ml-auto" min-width="0">
        <v-icon color="icon_default" class="pr-1">mdi-send</v-icon>
      </v-btn>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="!loading_download" @click.prevent="downloadVideo(item)" slot="end" color="icon_default" text
        class="ml-auto">
        <v-icon color="icon_default" class="pr-1">mdi-download</v-icon>
        Download
      </v-btn>
      <progress class="ml-auto" v-if="loading_download" :max="total" :value="loaded"></progress>
      <span class="mx-2" v-if="loading_download">{{ percentage }}%</span>
    </v-card-actions>
    <VideoSelecionado ref="videoDialog" :item="item" :videos_relacionados="videos_relacionados" :tags="tags"
      :categorias="categorias" :videoUrl="videoUrl" :exportarVideo="exportarVideo" :fetchData="fetchData"
      :changeEditModel="changeEditModel" :incrementarVisualizacao="incrementarVisualizacao"
      :incrementarDownloadVisualizacao="incrementarDownloadVisualizacao" />
    <LoadingModal ref="loadingModal" />
  </v-card>
</template>
<script>
import axios from "axios";
import { apiClientConversao, apiClient } from "@/services/api";
import VideoSelecionado from "./Modals/Conteudo/VideoSelecionado.vue";
import { mapActions, mapGetters } from "vuex";
import LoadingModal from "./Modals/LoadingModal.vue";
export default {
  props: {
    item: { type: Object, required: true },
    fetchData: Function,
    changeEditModel: Function,
  },
  components: { VideoSelecionado, LoadingModal },
  data() {
    return {
      pracaOptions: [
        { id: 1, name: "PAMRJ-MAG" },
        { id: 2, name: "PAMRJ-HN" },
        { id: 3, name: "PAM-BSB" },
        { id: 4, name: "PAM-SP" },
        { id: 5, name: "PAM-BH" },
        { id: 6, name: "VIZ-ESPORTE" },

      ],
      videoThumbUrl: null,
      videoUrl: null,
      showModalVideoSelecionado: false,
      id_praca: null,
      videos_relacionados: null,
      tags: null,
      categorias: null,
      loading_download: null,
      loaded: 0,
      total: 0,
      loading_exportar: false,
    };
  },
  computed: {
    ...mapGetters({
      filtros: "conteudos/getterFiltrosConteudo",
      lastDownload: "conteudos/getterLastDownload"
    }),
    duracao() {
      return this.item.duracao.substring(0, 8);
    },
    percentage() {
      if (this.total === 0) {
        return 0;
      } else {
        return Math.round((this.loaded / this.total) * 100);
      }
    },
  },
  methods: {
    ...mapActions({
      getConteudos: "conteudos/getConteudos",
      setLastDownload: "conteudos/lastDownload"
    }),
    async getVideoUrl() {
      await apiClientConversao
        .get(`/video/getsignedurlvisualizacao?idVideo=${this.item.videoId}`)
        .then((response) => {
          this.videoUrl = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async openVideoDialog() {
      this.$refs.videoDialog.openVideoDialog();
      await this.getRelatedVideos();
      await this.getTags();
      await this.getCategorias();
      await this.getVideoUrl();
      this.setLastDownload(null)
    },
    toggleLoadingModal() {
      this.$refs.loadingModal.toggleLoadingModal();
    },
    async downloadVideo(item, update = true) {
      try {
        if (item.nrDownload > item.maxDownload) {
          return this.$toast.info(
            "O número de downloads permitidos para esse vídeo já encerrou."
          );
        }

        const instance = axios.create();

        instance.defaults.onDownloadProgress = progressEvent => {
          this.loaded = progressEvent.loaded;
          this.total = progressEvent.total;
        };

        this.loading_download = true;
        await instance({
          url: item.video_url_download,
          method: "GET",
          responseType: "blob",

        })
          .then((response) => {
            let nome_download
            const href = URL.createObjectURL(response.data);
            const link = document.createElement("a");

            if (item.nome.includes('.mxf')) {
              nome_download = item.nome
            } else {
              nome_download = `${item.nome}.mxf`
            }

            console.log(nome_download)

            link.href = href;
            link.download = `${nome_download}`;
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(href);
          })
          .then(async () => {
            await this.incrementarDownloadVisualizacao(item, update);
          });
      } catch (error) {
        this.$toast.error(
          "Não foi possível iniciar o download, tente novamente."
        );
      } finally {
        this.loading_download = false;
      }
    },
    async exportarVideo(item, id_praca) {
      try {

        if (!id_praca) {
          return this.$toast.error('Selecione a praça.')
        }

        await apiClientConversao
          .post(`/video/exportfilepam?idconteudo=${item.id}&to=${id_praca}`)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success(
                "O vídeo foi adicionado a fila de exportação e estará disponível em breve."
              );
              this.toggleLoadingModal()
            }
          })
          .then(async () => {
            await this.incrementarDownloadVisualizacao(item, true).then(() => {
              setTimeout(() => {
                this.toggleLoadingModal()
              }, 5000)
            })
          })

      } catch (error) {
        console.log(error);
      }
    },
    async incrementarDownloadVisualizacao(item, update) {
      try {
        item.nrDownload += 1;
        item.alteradoEm = new Date();
        item.alteradoPor = this.$store.state.users.user.id
        item.LoginUsuarioLogado = this.$store.state.users.user.login

        await apiClient.patch(`/conteudo`, item).then(async (response) => {
          if (response.status === 200 && update) {
            await this.fetchData()
          }
        });
      } catch (error) {
        return error;
      }
    },
    async getRelatedVideos() {
      try {
        let conteudos;

        await apiClient
          .get(
            `/conteudo/getconteudobytagrelacionadas?idConteudo=${this.item.id}`
          )
          .then(async (response) => {
            const urls_download = await apiClientConversao.post(
              `/video/getlistsignedurldownload`,
              response.data
            );
            const urls_thumb = await apiClientConversao.post(
              `/video/getlistsignedurlthumb`,
              response.data
            );

            if (urls_download.status === 200 && urls_thumb.status === 200) {
              conteudos = await Promise.all(
                response.data.map(async (conteudo) => {
                  const response = await apiClientConversao.get(
                    `/video/getsignedurlvisualizacao?idVideo=${conteudo.videoId}`
                  );

                  const video_url = urls_download.data.filter(
                    (result) => result.idConteudo === conteudo.id
                  );
                  const thumb_url = urls_thumb.data.filter(
                    (result) => result.idConteudo === conteudo.id
                  );

                  return {
                    pracaId: conteudo.pracaId,
                    maxDownload: conteudo.maxDownload,
                    pesquisaRRD: conteudo.pesquisaRRD,
                    videoId: conteudo.videoId,
                    thumbId: conteudo.thumbId,
                    limiteArmazenamento: conteudo.limiteArmazenamento,
                    expurgado: conteudo.expurgado,
                    nrVisualizacao: conteudo.nrVisualizacao,
                    nrDownload: conteudo.nrDownload,
                    duracao: conteudo.duracao,
                    criadoPor: conteudo.criadoPor,
                    criadoEm: conteudo.criadoEm,
                    alteradoPor: conteudo.alteradoPor,
                    alteradoEm: conteudo.alteradoEm,
                    id: conteudo.id,
                    status: conteudo.status,
                    video_url_download:
                      video_url.length > 0 ? video_url[0].urlSigned : null,
                    thumb_url:
                      thumb_url.length > 0 ? thumb_url[0].urlSigned : null,
                    video_url_low: response.data,
                    nome: video_url.length > 0 ? video_url[0].nomeVideo : null
                  };
                })
              );
            }
          })
          .then(() => {
            this.videos_relacionados = conteudos;
          });
      } catch (error) {
        console.log(error);
      }
    },
    async getTags() {
      try {
        const response = await apiClient.get(
          `/tag/gettagbyidconteudo?idConteudo=${this.item.id}`
        );
        this.tags = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCategorias() {
      try {
        const response = await apiClient.get(
          `/categoria/getcategoriasbyidconteudo?idConteudo=${this.item.id}`
        );
        this.categorias = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async incrementarVisualizacao(item, update) {
      try {
        const seconds = new Date().getTime() / 1000 //Tempo da tada de hoje convertido em segundos

        if (this.lastDownload && (seconds - this.lastDownload) < 300) {
          return;
        }

        item.nrVisualizacao += 1;
        item.alteradoEm = new Date();
        item.alteradoPor = this.$store.state.users.user.id
        item.LoginUsuarioLogado = this.$store.state.users.user.login

        await apiClient.patch(`/conteudo/updatewhensee`, item).then(async (response) => {
          if (response.status === 200 && update) {
            await this.fetchData()
            this.setLastDownload(new Date())
          }
        });
      } catch (error) {
        console.log(error)
        return error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
progress::-moz-progress-bar {
  background: var(--v-primary-base);
}

progress::-webkit-progress-value {
  background: var(--v-primary-base);
}

progress {
  color: var(--v-primary-base);
}

.card {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.thumbImg {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
}

.cardSubtitle {
  background-color: RGBA(255, 255, 255, 0.9);
  height: 32px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.theme--dark .cardSubtitle {
  background-color: RGBA(26, 30, 32, 0.9);
}
</style>