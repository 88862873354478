import { apiClient } from "@/services/api";

const state = () => ({
  usuarios_table: null,
});

const getters = {
  getterUsuarios: state => {
    return state.usuarios_table;
  }
};

const actions = {
  async getUsuarios({ commit }, payload) {
    const { pagina, itensPorPagina, usuario, login } = payload;

    await apiClient.get(`usuario/getall?Paginar=true&Pagina=${pagina}&ItensPorPagina=${itensPorPagina}${usuario.trim() !== '' ? `&Usuario=${usuario}` : ''}${login.trim() !== '' ? `&Login=${login}` : ''}`)
      .then((response) => {
        commit('setUsuarios', response.data);
      }).catch((error) => {
        console.error("Erro ao buscar usuários", error);
      })
  }
};

const mutations = {
  setUsuarios(state, payload) {
    state.usuarios_table = payload
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
