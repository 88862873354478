import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView'
import AprovadorView from '../views/Admin/AprovadorView'
import CategoriaView from '../views/Admin/CategoriaView'
import ConteudoView from '../views/Admin/ConteudoView'
import LogView from '../views/Admin/LogView'
import ParametroView from '../views/Admin/ParametroView'
import RelatorioView from '../views/Admin/RelatorioView'
import SubCategoriaView from '../views/Admin/SubCategoriaView'
import UsuarioView from '../views/Admin/UsuarioView'
import LoginView from '../views/LoginView';
import CallBack from '@/views/CallBack';
import store from "../store";
// import { apiClient } from '@/services/api'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: "/auth/callback",
    name: "callback",
    component: CallBack,
  },
  {
    path: '/admin/categorias',
    component: CategoriaView,
    meta: {
      isAuthenticated: true,
      isAdmin: true
    }
  },
  {
    path: '/admin/aprovadores',
    component: AprovadorView,
    meta: {
      isAuthenticated: true,
      isAdmin: true
    }
  },
  {
    path: '/admin/conteudos',
    component: ConteudoView,
    meta: {
      isAuthenticated: true,
      isAdmin: true
    }
  },
  {
    path: '/admin/log',
    component: LogView,
    meta: {
      isAuthenticated: true,
      isAdmin: true
    }
  },
  {
    path: '/admin/parametros',
    component: ParametroView,
    meta: {
      isAuthenticated: true,
      isAdmin: true
    }
  },
  {
    path: '/admin/relatorios',
    component: RelatorioView,
    meta: {
      isAuthenticated: true,
      isAdmin: true
    }
  },
  {
    path: '/admin/subcategoria',
    component: SubCategoriaView,
    meta: {
      isAuthenticated: true,
      isAdmin: true
    }
  },
  {
    path: '/admin/usuarios',
    component: UsuarioView,
    meta: {
      isAuthenticated: true,
      isAdmin: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {



    if (to.matched.length < 1) {
      next("/");
    }

    // Caso o usuário esteja autenticado e for para página de login, é redirecionado para home.
    if (to.name === 'login') {
      if (store.getters["users/isAuthenticated"]) {
        next("/")
      }
    }

    //Rotas autenticadas
    if (to.matched.some(record => record.meta.isAuthenticated)) {
      if (store.getters["users/isAuthenticated"]) {
        if (to.matched.some(record => record.meta.isAdmin)) {
          if (store.getters["users/isAdm"]) {
            next();
          } else {
            next("/");
          }
        }
        next();
      } else {
        next("/login");
      }
    } else {
      next();
    }
  

  //404 fallback para home
});

export default router
