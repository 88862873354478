<template>
  <v-dialog
    v-model="dialog"
    width="630"
    min-height="600"
    style="background: var(--v-secondary-base)"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on" @click.prevent="fetchEditarData">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <video
              :key="video_low_url"
              width="580"
              height="240"
              controls
              ref="video"
            >
              <source :src="video_low_url" />
            </video>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <TagsCombobox
              v-model="tagsInput"
              :items="tags"
              @change="updateTagsObject"
              :editModel="editModel"
              :editar="true"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h2>Categorias</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div v-if="categoria_subcategoria">
              <div
                v-for="categoria in categoria_subcategoria"
                :key="categoria.id"
                class="categoriaWrapper"
              >
                <div color="input_color" class="categoriaHeaderWrapper">
                  <h1 style="font-size: 16px">{{ categoria.name }}</h1>
                  <v-btn icon @click="openSubcategoria(categoria.id)">
                    <v-icon> mdi-chevron-down </v-icon>
                  </v-btn>
                </div>

                <div
                  class="subcategoriaWrapper"
                  v-show="active === categoria.id"
                >
                  <!-- <div>
                    <input
                      type="checkbox"
                      :value="categoria.id"
                      v-model="checkAll"
                      @click="checkTodosCheckboxes(categoria.id)"
                    />
                    <label> TODAS </label>
                  </div> -->
                  <div
                    class="subCategoriaInputWrapper"
                    :key="subcategoria.id_subcategoria"
                    v-for="subcategoria in categoria.subcategorias"
                  >
                    <input
                      :id="categoria.id.toString()"
                      type="checkbox"
                      v-model="categoriasList"
                      :value="subcategoria.id_subcategoria"
                      @change="onCheckBoxChange"
                      class="categoriasCheckbox"
                      :name="`${categoria.id}categoriasCheckbox`"
                    />
                    <label>{{ subcategoria.name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-menu
              class="date_picker"
              v-model="modal_data_inicial"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  filled
                  v-model="data_inicial_formatada"
                  label="Período máximo de armazenamento do vídeo"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  background-color="gray4"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data_inicial"
                @input="modal_data_inicial = false"
                event-color="primary"
                color="primary"
                locale="pt-Br"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              background-color="input_color"
              filled
              label="Quantidade máx de downloads do vídeo"
              color="primary"
              v-model="maxDownloads"
              type="number"
            >
            </v-text-field
          ></v-col>
        </v-row>

        <!-- <v-row>
          <v-col>
            <v-carousel v-model="model">
              <v-carousel-item
                v-for="thumbnail in thumbnails"
                :key="thumbnail.id"
              >
                <v-img height="100% " :src="thumbnail.urlSigned">
                  <input
                    type="checkbox"
                    @click="(e) => onClick(e, thumbnail.id)"
                    class="imgCheckBox"
                    :id="thumbnail.id"
                  />
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col class="d-flex align-center justify-end">
            <v-btn color="#FFFFFF" @click.prevent="closeDialog" text>
              CANCELAR
            </v-btn>
            <v-btn
              :loading="loading"
              color="primary"
              @click.prevent="editarConteudo"
              class="text_action--text"
            >
              <strong> SALVAR </strong>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

 
<script>
import { apiClient, apiClientConversao } from "@/services/api";
import { convertSecondsToHHMMSS } from "@/utils/convertSecondsToHHMMSS";
import TagsCombobox from "./TagsCombobox.vue";
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
export default {
  props: {
    item: Object,
    searchField: String,
    categoria_id: { type: Number, default: null },
    subcategoria_id: { type: Number, default: null },
  },
  components: { TagsCombobox },
  data() {
    return {
      // checkAll: [],
      maxDownloads: this.item.maxDownload,
      dialog: false,
      categorias_id: null,
      idUsuario: null,
      tagsInput: null,
      subcategorias_id: null,
      status_id: null,
      file: null,
      url: null,
      tagsObj: [],
      categoriasList: [],
      pracaOptions: [
        { id: 1, name: "PAMRJ-MAG" },
        { id: 2, name: "PAMRJ-HN" },
        { id: 3, name: "PAM-BSB" },
        { id: 4, name: "PAM-SP" },
        { id: 5, name: "PAM-BH" },
        { id: 6, name: "VIZ-ESPORTE" },
        
      ],
      loading: false,
      form_wizard: false,
      conteudo_form_data: null,
      thumbnails: [],
      model: 0,
      thumbnail_id: null,
      checked: false,
      categoriasTreeview: null,
      data_inicial: new Date(this.item.limiteArmazenamento)
        .toISOString()
        .substring(0, 10),
      data_inicial_formatada: Intl.DateTimeFormat("pt-Br").format(
        new Date(this.item.limiteArmazenamento)
      ),
      modal_data_inicial: false,
      editModel: null,
      categoriaSubcategoriaFormatada: [],
      active: null,
      video_low_url: null,
    };
  },
  async created() {
    this.idUsuario = this.$store.state.users.user.id;
  },

  computed: {
    ...mapGetters({
      categorias: "categorias/getterCategorias",
      subcategorias: "subcategorias/getterSubcategorias",
      tags: "tags/getterTags",
      categoria_subcategoria: "categorias/getterCategoriasSubcategorias",
    }),
  },
  watch: {
    tagsInput() {
      setTimeout(async () => {
        await this.getTags({
          nome: this.tagsInput || "",
          pagina: 1,
          itensPorPagina: 100,
        });
      }, 1500);
    },
    data_inicial(val) {
      this.data_inicial_formatada = dayjs(val).format("DD/MM/YYYY");
    },
  },
  methods: {
    // checkTodosCheckboxes(categoria_id) {
    //   const elements = document.querySelectorAll(
    //     `[name="${categoria_id}categoriasCheckbox"]`
    //   );

    //   const elementsChecked = [];

    //   elements.forEach((element) => {
    //     if (element.checked) {
    //       elementsChecked.push(element);
    //     }
    //   });

    //   if(elementsChecked.length === elements.length) {

    //   }

    // },
    openSubcategoria(id_categoria) {
      if (this.active === +id_categoria) {
        this.active = null;
        return;
      }
      this.active = id_categoria;

      return;
    },
    onCheckBoxChange() {
      this.categoriaSubcategoriaFormatada = [];
      const elements = document.querySelectorAll(".categoriasCheckbox");

      for (let i = 0; i <= elements.length - 1; i++) {
        if (elements[i].checked === true) {
          this.categoriaSubcategoriaFormatada.push({
            id_categoria: +elements[i].id,
            id_subcategoria: +elements[i].value,
          });
        }
      }
    },
    async fetchEditarData() {
      let categoriaSubcategoria = [];

      const response = await apiClient.get(
        `/conteudo/getbyidconteudodetails?idConteudo=${this.item.id}`
      );

      await apiClientConversao
        .get(`/video/getsignedurlvisualizacao?idVideo=${this.item.videoId}`)
        .then((response) => {
          this.video_low_url = response.data;
        });

      if (response.status === 200) {
        response.data[0].listCategoriaSubCategoria.map(
          (categoria_subcategoria) => {
            categoriaSubcategoria.push(categoria_subcategoria.subCategoriaId);
            this.categoriaSubcategoriaFormatada.push({
              id_categoria: categoria_subcategoria.id,
              id_subcategoria: categoria_subcategoria.subCategoriaId,
            });
          }
        );

        this.categoriasList = categoriaSubcategoria;
        this.editModel = response.data[0].listTag;
      }

      return;
    },
    ...mapActions({
      getTags: "tags/getTags",
      getConteudos: "conteudos/getConteudos",
    }),
    updateTagsObject(value) {
      this.tagsObj = value;
    },

    // onClick(e, thumbnail_id) {
    //   this.thumbnail_id = thumbnail_id;
    //   if (e.target.checked === true) {
    //     const elements = document.getElementsByClassName("imgCheckBox");
    //     for (let i = 0; i <= elements.length - 1; i++) {
    //       if (elements[i].id !== e.target.id) {
    //         elements[i].checked = false;
    //       }
    //     }
    //     return;
    //   }
    // },

    closeDialog() {
      this.dialog = false;
      this.categorias_id = null;
      this.subcategorias_id = null;
      this.status_id = null;
      this.file = null;
      this.url = null;
      this.form_wizard = null;
    },

    async editarConteudo() {
      try {
        this.loading = true;

        if (!this.maxDownloads) {
          return this.$toast.error("Insira o número máximo de downloads!");
        }

        if (this.categoriasList.length < 0) {
          return this.$toast.error("Selecione uma categoria/subcategoria!");
        }

        const duration = convertSecondsToHHMMSS(this.$refs.video.duration);

        const payload = {
          id: this.item.id,
          status: this.item.status,
          criadoPor: this.item.criadoPor,
          criadoEm: this.item.criadoEm,
          maxDownload: this.maxDownloads,
          videoId: this.item.videoId,
          thumbId: this.item.thumbId,
          alteradoPor: this.$store.state.users.user.id,
          alteradoEm: new Date(),
          pracaId: null,
          limiteArmazenamento: new Date(this.data_inicial),
          nrVisualizacao: this.item.nrVisualizacao,
          nrDownload: this.item.nrDownload,
          duracao: duration,
        };

        const response = await apiClient.put("/conteudo", payload);

        if (response.status === 200) {
          let payload_categoria = [];
          let payload_tag = [];

          this.categoriaSubcategoriaFormatada.map((categoria) => {
            const data = {
              conteudoId: response.data.id,
              categoriaId: categoria.id_categoria,
              subcategoriaId: categoria.id_subcategoria,
            };
            payload_categoria.push(data);
          });

          this.tagsObj.map((tag) => {
            const data = {
              alteradoEm: new Date(),
              criadoEm: tag.criadoEm,
              alteradoPor: this.$store.state.users.user.id,
              criadoPor: tag.criadoPor,
              id: tag.id,
              idConteudo: response.data.id,
              nome: tag.nome,
              status: 1,
            };
            payload_tag.push(data);
          });

          await this.updateCategoriaSubCategoria(payload_categoria);

          await this.updateTags(payload_tag);

          await this.getConteudos({
            tag: this.searchField,
            categoria: this.categoria_id,
            subcategoria: this.subcategoria_id,
            status: null,
            praca: null,
            pagina: 1,
            itensPorPagina: 1000,
          });

          this.dialog = false;
          this.active = null;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async updateCategoriaSubCategoria(payload) {
      try {
        await apiClient.post(
          "/conteudo/updateconteudocategoriasubcateria",
          payload
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateTags(payload) {
      try {
        await apiClient.post("/tag/updateconteudotag", payload);
      } catch (error) {
        console.log(error);
      }
    },

    generateVideoThumbnail(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        // this is important
        video.autoplay = true;
        video.muted = true;
        video.src = URL.createObjectURL(file);

        video.onloadeddata = () => {
          let ctx = canvas.getContext("2d");

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          video.pause();
          return resolve(canvas.toDataURL("image/png"));
        };
      });
    },

    async createBlob(base64) {
      let res = await fetch(base64);
      let myBlob = await res.blob();

      return myBlob;
    },

    convertSecondsToHHMMSS(seconds) {
      var date = new Date(null);
      date.setSeconds(seconds);
      return date.toISOString().substring(11, 8);
    },
  },
};
</script>

<style lang="scss" scoped>
.inputControl {
  background-color: var(--v-primary-base);
  width: 240px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  label {
    color: #ffffff;
  }
  input {
    display: none;
  }
}

.categoriaHeaderWrapper {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  background: var(--v-input_color-base);

  h1 {
    color: var(--v-text_primary-base);
  }

  .v-btn {
    margin-left: auto;
  }
}

.categoriaWrapper + .categoriaWrapper {
  margin-top: 0.1rem;
}

.subcategoriaWrapper {
  padding: 0 24px 16px;
  background: var(--v-input_color-base);
}

.subCategoriaInputWrapper {
  label {
    margin-left: 8px;
    font-size: 16px;
  }
}
</style>