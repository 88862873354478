import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#FF631C',
        secondary: '#E0E0E0',
        background: '#EEEEEE',
        accent: '#82B1FF',
        error: '#EA4B4B',
        info: '#2AB0FC',
        success: '#38C976',
        warning: '#FCC132',
        text_action: '#000000',
        surface_primary: '#FFFFFF',
        surface_tertiary: '#EEEEEE',
        input_color: '#FFFFFF',
        dialog_bg: '#EEEEEE',
        app_bar_color: '#FFFFFF',
        table_header: '#CCCCCC',
        table_body: '#F5F5F5',
        button_color: '#000000',
        text_primary: '#171717',
        text_secondary: '#323232',
        secondary2: '#FFFFFF',
        icon_default: '#171717',
        table_hover: '#FFFFFF',
        gray1: '#323232',
        modal_text: '#323232',
        card_bg: '#FFFFFF'
      },
      dark: {
        primary: '#FF631C',
        background: '#101010',
        text_secondary: "#9e9e9e",
        input_color: "#141518",
        text_action: '#000000',
        surface_primary: '#1F1F1F',
        surface_secondary: "#101010",
        surface_tertiary: "#1F1F1F",
        secondary: '#1A1E20',
        secondary2: '#2B2625',
        white: '#FFFFFF',
        gray1: '#F8F9FA',
        gray2: '#F1F3F5',
        gray3: '#A1A1A3',
        gray4: '#141518',
        accent: '#82B1FF',
        error: '#EA4B4B',
        info: '#2AB0FC',
        success: '#38C976',
        warning: '#FCC132',
        gray: '#676767',
        dialog_bg: '#1A1E20',
        app_bar_color: '#1A1E20',
        table_header: '#1A1E20',
        table_body: '#141518',
        button_color: '#FFFFFF',
        text_primary: '#A1A1A3',
        icon_default: '#E0E0E0',
        table_hover: '#323232',
        modal_text: '#FFFFFF',
        card_bg: '#1a1e20'
      },
    },
    options: {
      customProperties: true
    }
  },
});
