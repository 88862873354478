<template>
  <div>
    <v-row>
      <v-col cols="12" sm="10" md="5" class="searchWrapper">
        <v-text-field prepend-inner-icon="mdi-magnify" placeholder="Buscar" filled background-color="input_color"
          v-model="nome" class="search" @input="onInput">
        </v-text-field>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="3">
        <AdicionarEditarSubcategoria v-if="categorias" :adicionar="true" :categorias="categorias.lstResultado"
          :fetchData="fetchData" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10" md="5">
        <v-select v-if="categorias" :style="{ width: '15.875rem' }"
          :items="[{ nome: 'TODAS', id: null }, ...categorias.lstResultado]" item-text="nome" item-value="id"
          label="Categorias" filled v-model="categoria_id" background-color="input_color" @input="onInput"></v-select>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-col>
        <v-data-table hide-default-footer :headers="headers" :items="subcategorias.lstResultado"
          :items-per-page="itensPorPagina" class="elevation-1 secondary mt-8" v-if="subcategorias" :loading="loading"
          :header-props="headerProps" :page="pagina" :v-model="pagina">
          <template v-slot:item="row">
            <tr :key="row.item.id">
              <td>{{ row.item.nome }}</td>
              <td>{{ row.item.nomeCategoria }}</td>
              <td class="d-flex align-center justify-end pa-0">
                <v-divider vertical></v-divider>
                <AdicionarEditarSubcategoria v-if="categorias" :categorias="categorias.lstResultado"
                  :editarData="row.item" :fetchData="fetchData" />
                <v-divider vertical></v-divider>

                <RemoverSubCategoria :deleteSubCategoria="deleteSubCategoria" :id="row.item.id"
                  :nomeSubcategoria="row.item.nome" />
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-pagination class="mt-5" v-model="pagina" :length="totalPages" @input="handlePageChange" :total-visible="8" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AdicionarEditarSubcategoria from "@/components/Modals/Subcategoria/AdicionarEditarSubCategoria.vue";
import RemoverSubCategoria from "@/components/Modals/Subcategoria/RemoverSubCategoria.vue";
import { mapActions, mapGetters } from "vuex";
import { apiClient } from "@/services/api";
export default {
  components: { AdicionarEditarSubcategoria, RemoverSubCategoria },
  data() {
    return {
      nome: "",
      categoria_id: null,
      loading: false,
      pagina: 1,
      itensPorPagina: 10,
      // size: null,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: false,
        },
        {
          text: "Categorias",
          align: "start",
          sortable: false,
        },
        {
          text: "Ação",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ],

      headerProps: {
        mobile: true,
        checkboxColor: "success--text",
      },
    };
  },
  async created() {
    await this.getCategorias({
      pagina: 1,
      itensPorPagina: 100,
      nome: "",
    });

    await this.fetchData();
  },
  computed: {
    ...mapGetters({
      categorias: "categorias/getterCategorias",
      subcategorias: "subcategorias/getterSubcategorias",
    }),

    totalPages() {
      let pages = null;
      if (this.subcategorias) {
        pages = Math.ceil(this.subcategorias.total / this.itensPorPagina);
      }
      return pages;
    },
  },
  methods: {
    ...mapActions({
      getCategorias: "categorias/getCategorias",
      getSubCategorias: "subcategorias/getSubCategorias",
    }),
    async deleteSubCategoria(id) {
      try {
        const response = await apiClient.delete(`/subcategoria/${id}`);
        if (response.status === 200) {
          await this.fetchData();
        }
      } catch (error) {
        return alert(error.message);
      }
    },
    onInput() {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(async () => {
        await this.fetchData();
      }, 1500);
    },
    async fetchData() {
      try {
        this.loading = true;
        await this.getSubCategorias({
          nome: this.nome,
          categoria_id: this.categoria_id,
          pagina: this.pagina,
          itensPorPagina: this.itensPorPagina,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handlePageChange() {
      await this.fetchData();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchAddCategoria {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

div.searchWrapper {
  align-self: flex-start;
  height: 40px;
}

.search {
  width: 100%;
  height: 100%;
}
</style>