import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import './registerServiceWorker'
import store from './store'
import router from './router'

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";


Vue.use(Toast, { position: POSITION.TOP_CENTER, timeout: 3000 });


new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
