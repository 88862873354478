import * as R from "ramda";
import * as qs from "querystring";
import * as urlParser from "url";

const redirect_uri = process.env.VUE_APP_REDIRECT_URI;
const open_connect_auth = process.env.VUE_APP_OPEN_CONNECT_AUTH;
const urlLogoutOpenConnectId = process.env.VUE_APP_OPEN_CONNECT_LOGOUT;

const queryStringParamsAutorizationUrl = clientId => {
  return {
    scope: "openid legado",
    response_type: "code",
    client_id: clientId,
    redirect_uri: redirect_uri,
  };
};

const composeUrlAutorization = R.compose(
  R.prop("href"),
  urlParser.parse,
  R.concat(`${open_connect_auth}?`),
  qs.stringify,
  queryStringParamsAutorizationUrl
);

const getAuthorizationUrl = clientId => {
  return composeUrlAutorization(clientId);
};

export { getAuthorizationUrl, urlLogoutOpenConnectId };
