<template>
  <div class="contentWrapper">
    <v-row>
      <v-col cols="12">
        <v-tabs center-active background-color="background">
          <v-tab>Log Aplicação</v-tab>
          <v-tab>Log Serviço</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row class="datasWrapper">
      <v-col cols="12" sm="6" md="3">
        <v-menu v-model="modal_data_inicial" :close-on-content-click="false" :nudge-right="40"
          transition="scale-transition" offset-y min-width="auto" class="date_picker">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field filled v-model="data_inicial_formatada" label="Início" append-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on" background-color="input_color"></v-text-field>
          </template>
          <v-date-picker v-model="data_inicial" @input="modal_data_inicial = false" color="primary"
            locale="pt-Br"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-menu class="date_picker" v-model="modal_data_final" :close-on-content-click="false" :nudge-right="40"
          transition="scale-transition" offset-y min-width="auto" color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field filled v-model="data_final_formatada" label="Final" append-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on" background-color="input_color"></v-text-field>
          </template>
          <v-date-picker v-model="data_final" @input="modal_data_final = false" @change="fetchData"
            locale="pt-Br"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row class="filtrosWrapper">
      <v-col cols="12" sm="6" md="3">
        <v-select v-if="users" v-model="nomeUsuario" filled label="Usuários" background-color="input_color"
          :items="[{ id: 0, nome: 'Todos' }, ...users.lstResultado]" @input="onInput" item-text="nome"
          item-value="nome"></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select filled label="Funcionalidade" background-color="input_color" :items="tabelas" v-model="funcionalidade"
          @input="onInput"></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select filled label="Ação" background-color="input_color" :items="acoes" v-model="acao" @input="onInput">
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn @click.prevent="exportarExcel" color="primary">
          Exportar em excel
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table hide-default-footer v-if="log" :headers="headers" :items="log.lstResultado" class="elevation-1"
          :loading="loading" :items-per-page="itensPorPagina" :header-props="headerProps">
          <template v-slot:item="row">
            <tr :key="row.item.id" style="background-color: ">
              <td>
                {{
                  Intl.DateTimeFormat("pt-Br").format(new Date(row.item.data))
                }}
              </td>
              <td>{{ row.item.usuarioId }}</td>
              <td>{{ row.item.tabela }}</td>
              <td>{{ row.item.tabela }}</td>
              <td>{{ row.item.acao }}</td>
              <td>{{ row.item.itemId }}</td>
            </tr>
          </template>

        </v-data-table>
        <v-pagination class="mt-5" v-model="pagina" :length="totalPages" @input="handlePageChange" :total-visible="8" />

      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import { apiClient } from "@/services/api";
export default {
  data() {
    return {
      data_inicial: null,
      limitData: 10000,
      data_final: null,
      data_inicial_formatada: null,
      data_final_formatada: null,
      modal_data_inicial: false,
      modal_data_final: false,
      acao: "",
      funcionalidade: "",
      nomeUsuario: "Todos",
      loading: false,
      pagina: 1,
      itensPorPagina: 10,
      headers: [
        {
          text: "Data",
          align: "start",
          sortable: false,
        },
        {
          text: "Usuário",
          align: "start",
          sortable: false,
        },
        {
          text: "Área",
          align: "start",
          sortable: false,
        },
        {
          text: "Funcionalidade",
          align: "start",
          sortable: false,
        },
        {
          text: "Ação",
          align: "start",
          sortable: false,
        },
        {
          text: "Item",
          align: "start",
          sortable: false,
        },
      ],
      headerProps: {
        mobile: true,
        checkboxColor: "success--text",
      },
      tabelas: ['Todas', 'Categoria', 'Subcategoria', 'Conteúdo', 'Parâmetro', 'Usuário', 'Praça', 'Relatório'],
      acoes: ['Criação', 'Exclusão', 'Alteração', 'Download', 'Detalhe']
    };
  },
  async created() {
    await this.getUsuarios({
      pagina: 1,
      usuario: "",
      login: "",
      itensPorPagina: 2000
    })
    await this.fetchData();
  },
  methods: {
    onInput() {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(async () => {
        await this.fetchData();
      }, 1500);
    },
    ...mapActions({
      getLog: "log/getLog",
      getUsuarios: "users_table/getUsuarios"
    }),
    async fetchData() {
      try {
        this.loading = true;

        const checkDate = this.checkDates(this.data_inicial, this.data_final);

        if (checkDate.valid !== true) {
          return this.$toast.error(checkDate.msg);
        }

        const data_hoje = new Date();

        const data_limite = new Date(
          `${data_hoje.getFullYear()}/${data_hoje.getMonth()
          }/${data_hoje.getDate()}`
        )

        const dataInicial =
          this.data_inicial !== null
            ? new Date(this.data_inicial)
            : new Date(data_limite);
        const dataFinal =
          this.data_final !== null
            ? new Date(this.data_final)
            : data_hoje;

        dataFinal.setHours(20, 59, 0, 0)

        await this.getLog({
          pagina: this.pagina,
          itensPorPagina: this.itensPorPagina,
          dataInicial,
          dataFinal,
          nomeUsuario: this.nomeUsuario,
          acao: this.acao,
          funcionalidade: this.funcionalidade
        });

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handlePageChange() {
      await this.fetchData();
    },
    async exportarExcel() {
      try {
        const query = `${this.nomeUsuario.trim() !== '' && this.nomeUsuario !== 'Todos' ? `&NomeUsuario=${this.nomeUsuario}` : ''}${this.funcionalidade.trim() !== '' && this.funcionalidade !== 'Todas' ? `&Funcionalidade=${this.funcionalidade}` : ''}${this.acao.trim() !== '' && this.acao !== 'Todas' ? `&Acao=${this.acao}` : ''}`

        const data_hoje = new Date();

        const data_limite = new Date(
          `${data_hoje.getFullYear()}/${data_hoje.getMonth() + 1
          }/${data_hoje.getDate()}`
        )

        const checkDate = this.checkDates(this.data_inicial, this.data_final);

        if (checkDate.valid !== true) {
          return this.$toast.error(checkDate.msg);
        }

        const dataInicial =
          this.data_inicial !== null
            ? new Date(this.data_inicial)
            : new Date(data_limite)

        const dataFinal =
          this.data_final !== null
            ? new Date(this.data_final)
            : data_hoje;


        dataFinal.setHours(20, 59, 0, 0)

        const response = await apiClient.get(
          `/log?Paginar=true&GerarPlanilha=true&DataInicio=${dataInicial.toISOString()}&DataFim=${dataFinal.toISOString()}&ItensPorPagina=3000${query}`,
          { responseType: "blob" }
        );

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `relatorio_log_${new Date().toLocaleDateString()}`;
        link.click();
        link.remove();

        if (response.status === 200) {
          return this.$toast.success("Relatório exportado com sucesso!");
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.exportar_loading = true;
      }
    },
    checkDates(data_inicio, data_fim) {
      if (data_inicio === null && data_fim === null) {
        return {
          valid: true,
        };
      }

      if (data_inicio === null && data_fim !== null) {
        return {
          valid: false,
          msg: "Selecione uma data inicial.",
        };
      }

      if (data_inicio !== null && data_fim === null) {
        return {
          valid: false,
          msg: "Selecione uma data final.",
        };
      }

      const data_inicial = dayjs(data_inicio);
      const data_final = dayjs(data_fim);
      const data_hoje = dayjs();
      const diff = dayjs(data_final).diff(data_inicial, "days");

      if (diff > 30) {
        return {
          valid: false,
          msg: "A diferença entre a data inicial e a data final deve ser no máximo de 30 dias.",
        };
      }

      if (data_inicial > data_final) {
        return {
          valid: false,
          msg: "A data inicial não pode ser maior que a data final!",
        };
      }

      if (data_inicial > data_hoje) {
        return {
          valid: false,
          msg: "A data inicial não pode ser maior que a data atual!",
        };
      }

      if (data_final > data_hoje) {
        return {
          valid: false,
          msg: "A data final não pode ser maior que a data atual!",
        };
      }

      return {
        valid: true,
      };
    },
  },
  computed: {
    ...mapGetters({
      log: "log/getterLog",
      users: "users_table/getterUsuarios",
    }),
    totalPages() {
      let pages = null;
      if (this.log) {
        pages = Math.ceil(this.log.total / this.itensPorPagina);
      }
      return pages;
    },
  },
  watch: {
    data_inicial(val) {
      this.data_inicial_formatada = dayjs(val).format("DD/MM/YYYY");
    },
    data_final(val) {
      this.data_final_formatada = dayjs(val).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
}

.dataWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
}

.filtroswrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  margin-top: -30px;
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>