<template>
  <div class="text-center">
    <v-dialog v-model="removerDialog" width="500" height="180">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <v-card class="card">
        <v-card-title>
          Excluir categoria
          <v-spacer></v-spacer>
          <v-btn icon @click="removerDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="cardText">
          <p>Tem certeza que deseja excluir essa categoria?</p>
          <p style="color: var(--v-modal_text-base)">
            <strong> {{ nomeCategoria.toUpperCase() }} </strong>
          </p>
          <div class="buttonsWrapper">
            <v-btn @click.prevent="removerDialog = false" text>
              CANCELAR
            </v-btn>
            <v-btn color="error" @click.prevent="confirmar"> EXCLUIR </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

 
<script>
export default {
  props: {
    id: { type: Number, required: true },
    nomeCategoria: { type: String, required: true },
    deleteCategoria: { type: Function, required: true },
    fetchData: { type: Function, required: true },
  },
  data() {
    return {
      valid: false,
      title: "",
      type: "",
      removerDialog: false,
    };
  },
  methods: {
    async confirmar() {
      await this.deleteCategoria(this.id);
      this.removerDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cardText {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--v-gray3-base);
  }
  p + p {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: var(--v-gray1-base);
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .v-btn {
    width: 110px;
    height: 36px;
  }
}
</style>