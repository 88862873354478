import { apiClient } from "@/services/api";

const state = () => ({
  tags: null,
  limparTagsObj: false
});

const getters = {
  getterTags: state => {
    return state.tags
  },
  getterCleanTagsObj: state => {
    return state.limparTagsObj
  }
}

const actions = {
  async getTags({ commit }, payload) {
    const { pagina, itensPorPagina, nome } = payload;
    let query;
    if (nome.trim() === '') {
      query = `tag?Pagina=${pagina}&ItensPorPagina=${itensPorPagina}`
    } else {
      query = `tag?Pagina=${pagina}&ItensPorPagina=${itensPorPagina}&nome=${nome}`
    }
    await apiClient.get(query)
      .then((response) => {
        commit('setTags', response.data.lstResultado);
      }).catch(error => {
        console.error(error);
      })
  },
  async setCleanTag({ commit }, payload) {

    commit('setCleanTag', payload)
  }
}

const mutations = {
  setTags(state, payload) {
    state.tags = payload;
  },
  setCleanTag(state, payload) {
    state.limparTagsObj = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};