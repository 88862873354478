import { userKey } from "../../config/setting.js";
import { urlOpenConnectId } from "../../config/setting.js";
import { urlLogoutOpenConnectId } from "../../config/openConnect.js";
import axios from "axios";
import qs from "querystring";
import jwt from "jsonwebtoken";
import jwkToPem from "jwk-to-pem";
import * as R from "ramda";

const redirect_uri = process.env.VUE_APP_REDIRECT_URI;
const TOKEN_URL = process.env.VUE_APP_OPEN_CONNECT_TOKEN;
const openConnectClientId = process.env.VUE_APP_OPEN_CONNECT_CLIENT_ID;
const openConnectSecrect = process.env.VUE_APP_OPEN_CONNECT_SECRET;

// check localStorage FIRST and then try session storage
const state = () => ({
  status: "",
});

const getters = {
  getToken: (state) => state.token,
};

const actions = {
  ["AUTH_REQUEST"]: ({ commit }) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        window.location.href = urlOpenConnectId;
      } catch (error) {
        await commit("AUTH_ERROR");
        reject(error);
      }
    });
  },
  ["AUTH_REQUEST_TOKEN"]: ({ commit }, params) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const { code } = params;

      const credentials = Buffer.from(
        `${openConnectClientId}:${openConnectSecrect}`
      ).toString("base64");

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${credentials}`,
      };

      const reqBody = {
        code,
        grant_type: "authorization_code",
        redirect_uri,
      };

      try {
        const response = await axios.post(
          `${TOKEN_URL}`,
          qs.stringify(reqBody),
          {
            headers: headers,
          }
        );

        const validate = await axios.get(
          process.env.VUE_APP_OPEN_CONNECT_TOKEN_JWK
        );
        const pem = jwkToPem(R.head(validate.data.keys));
        let userData = jwt.verify(response.data.access_token, pem);

        commit("AUTH_SUCCESS");
        resolve({ ...userData, access_token: response.data.access_token });
      } catch (error) {
        reject(error);
      }
    });
  },
  ["AUTH_LOGOUT"]: ({ commit }) => {
    localStorage.removeItem(userKey);
    window.location.href = urlLogoutOpenConnectId;
    commit("AUTH_LOGOUT");
  },
};

const mutations = {
  ["AUTH_REQUEST"]: (state) => {
    state.status = "loading";
  },
  ["AUTH_SUCCESS"]: (state) => {
    state.status = "success";
  },
  ["AUTH_ERROR"]: (state) => {
    state.status = "error";
  },
  ["AUTH_LOGOUT"]: (state) => {
    state.token = "";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
