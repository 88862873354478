<template>
  <v-dialog v-model="dialog" max-width="500" max-height="180">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="addCategoriaButton"
        v-bind="attrs"
        v-on="on"
        v-if="adicionar"
      >
        Adicionar categoria
      </v-btn>
      <v-btn text v-bind="attrs" v-on="on" v-else>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="mb-8">
        {{ adicionar ? "Adicionar categoria" : "Editar categoria" }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <v-form ref="form" lazy-validation style="width: 100%">
          <v-text-field
            background-color="input_color"
            filled
            label="Nome"
            v-model="nome"
          >
          </v-text-field>
          <div class="buttonsWrapper mt-10">
            <v-btn @click.prevent="dialog = false" class="mr-8" text>
              CANCELAR
            </v-btn>
            <v-btn
              color="primary"
              @click.prevent="
                adicionar ? inserirCategoria() : editarCategoria()
              "
            >
              SALVAR
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

 
<script>
import store from "@/store";
import { apiClient } from "@/services/api";
import { mapActions } from "vuex";
export default {
  props: {
    adicionar: { type: Boolean, required: false },
    editarData: { type: Object, required: false },
  },
  data() {
    return {
      nome: "",
      dialog: false,
    };
  },
  mounted() {
    if (this.editarData) {
      this.nome = this.editarData.nome;
    }
  },
  methods: {
    ...mapActions({
      getCategorias: "categorias/getCategorias",
    }),
    async inserirCategoria() {
      await apiClient
        .post("/categoria", {
          nome: this.nome,
          criadoEm: new Date(),
          criadoPor: store.state.users.user.id,
          alteradoPor: store.state.users.user.id,
          alteradoEm: new Date(),
          status: 1,
        })
        .then(async (response) => {
          if (response.status === 200) {
            await this.getCategorias({
              nome: "",
              pagina: 1,
              itensPorPagina: 1000,
            });
          }
        })
        .catch((error) => {
          return alert(error.message);
        })
        .finally(() => {
          this.nome = "";
          this.dialog = false;
        });
    },

    async editarCategoria() {
      try {
        const response = await apiClient.put("/categoria", {
          id: this.editarData.id,
          nome: this.nome,
          criadoEm: this.editarData.criadoEm,
          criadoPor: this.editarData.criadoPor,
          alteradoPor: store.state.users.user.id,
          alteradoEm: new Date(),
          status: 1,
        });

        if (response.status === 200) {
          await this.getCategorias({
            nome: "",
            pagina: 1,
            itensPorPagina: 1000,
          });
        }
      } catch (error) {
        return alert(error.message);
      } finally {
        this.nome = "";
        this.dialog = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addCategoriaButton {
  background: var(--v-primary-base) !important;
  color: var(--v-gray4-base);
  span {
    text-transform: uppercase;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .v-btn {
    width: 110px;
    height: 36px;
  }
}
</style>
