<template>
  <v-dialog v-model="dialog" width="600" height="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on" class="text_secondary--text">
        <v-icon class="pr-2">mdi-chat-question</v-icon>Fale Conosco
      </v-btn>
    </template>

    <v-card style="background-color: var(--v-secondary-base)">
      <v-card-title class="mb-8">
        Fale Conosco
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon style="color: #a1a1a3; max-width: 14px"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="cardText">
        <v-form ref="form" v-model="valid" style="width: 100%; height: 100%" background-color="input_color">
          <v-row>
            <v-text-field v-model="title" class="inputLabel mb-8" label="Titulo *" required filled
              background-color="input_color">
            </v-text-field>
          </v-row>

          <v-row>
            <v-select :items="tipos" v-model="type" class="inputLabel mb-8" label="Tipo *" required filled
              background-color="input_color">
            </v-select>
          </v-row>

          <v-row>
            <v-textarea v-model="mensagem" class="inputLabel mb-8" filled name="input-7-4" label="Mensagem *"
              background-color="input_color">
            </v-textarea>
          </v-row>

          <v-row>
            <v-btn text class="ml-auto mr-8" @click="dialog = false">
              CANCELAR
            </v-btn>
            <v-btn @click.prevent="enviarMensagem" color="primary" class="mx-2 text_action--text">
              ENVIAR
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiClient } from '@/services/api';
export default {
  data() {
    return {
      valid: false,
      title: "",
      type: "",
      mensagem: "",
      dialog: false,
      tipos: ["Sugestão", "Dúvidas", "Elogio", "Solicitação de Vídeos"],
    };
  },
  methods: {
    closeDialog() {
      this.title = ""
      this.type = ""
      this.mensagem = ""
      this.dialog = false
    },
    async enviarMensagem() {
      try {
        const payload = {
          email: this.$store.state.users.user.email,
          tipo: this.type,
          assunto: this.title,
          conteudo: this.mensagem
        }

        await apiClient.post(`/enviar`, payload).then(() => {
          this.$toast.success(`${this.type} enviado(a).`)
          this.closeDialog()
        })
      } catch (error) {
        this.$toast.error(error.message)
        return
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inputLabel {
  color: var(--v-background-base);
}

.cardText.v-input__slot {
  background: var(--v-background-base) !important;
}

.cardText.v-text-field__slot {
  background: var(--v-background-base);
}

.cardText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: space-between;
  justify-items: center;
}

.v-card__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

// form,
// .v-form {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   align-content: space-between;
//   justify-items: center;
// }

// .v-label {
//   color: #a1a1a3;
//   font-size: 16px;
//   line-height: 22px;
//   font-weight: 400;
// }

// .buttons {
//   // width: 100%;
//   align-self: flex-end;
//   display: flex;
//   flex-direction: row;
//   align-items: flex-end;
//   align-content: space-between;
//   // padding-right: 5px;
// }
</style>