<template>
  <v-menu style="margin-top: 20px !important" bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="changeRouteIcon" icon v-bind="attrs" v-on="on" height="24" width="24">
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-list color="app_bar_color" width="200px">
      <template v-if="isAdm">
        <v-list-item v-if="isHome" class="pa-1">
          <v-list-item-title>
            <router-link to="/admin/categorias">
              <v-icon color="icon_default">mdi-cog</v-icon>
              <span class="text_secondary--text"> Configurações </span>
            </router-link>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-else class="pa-1">
          <v-list-item-title>
            <router-link to="/">
              <v-icon color="icon_default">mdi-home</v-icon>
              <span class="text_secondary--text"> Home </span>
            </router-link>
          </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>
      </template>

      <v-list-item class="pa-1">
        <v-list-item-title class="d-flex align-center">
          <button @click.prevent="signOut">
            <v-icon color="icon_default">mdi-logout</v-icon>
            <span class="text_secondary--text"> Sair do Poupa Tempo </span>
          </button>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isHome: false,
    };
  },
  created() {
    this.changeRouteIcon();
  },
  methods: {
    ...mapActions({ logout: "users/logout" }),
    async signOut() {
      await this.logout();
    },
    changeRouteIcon() {
      // console.log(this.$router.history.current["path"]);
      this.isHome = this.$router.history.current["path"] === "/";
    },
  },
  computed: {
    ...mapGetters({
      isAdm: "users/isAdm"
    }),
  }
};
</script>

<style lang="scss" scoped></style>
