<template>
  <svg
    width="227"
    height="32"
    viewBox="0 0 227 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9841 0C7.17535 0 0 7.17535 0 15.9841C0 24.8251 7.17535 32 15.9841 32C24.7928 32 32 24.8247 32 15.9841C32 7.17535 24.7928 0 15.9841 0ZM26.907 23.5438C23.4155 23.9281 19.6357 24.0241 15.9841 24.0241C12.3325 24.0241 8.55265 23.9281 5.02888 23.5438C4.03593 23.4478 3.52331 23.1913 3.39504 22.1342C3.20309 20.116 3.20309 18.066 3.20309 15.9841C3.20309 13.934 3.20309 11.884 3.39504 9.86582C3.52331 8.80873 4.03548 8.55265 5.02888 8.45622C8.55219 8.07187 12.332 7.97589 15.9836 7.97589C19.6352 7.97589 23.4151 8.07187 26.9065 8.45622C27.9318 8.5522 28.4439 8.80873 28.5722 9.86536C28.7323 11.8836 28.7323 13.9336 28.7323 15.9836C28.7323 18.0655 28.7323 20.1155 28.5722 22.1337C28.4444 23.1913 27.9318 23.4473 26.907 23.5438Z"
      fill="url(#paint0_linear_1055_2614)"
    />
    <path
      d="M9.51367 15.984C9.51367 12.4607 12.3324 9.51367 15.984 9.51367C19.6356 9.51367 22.4543 12.4607 22.4543 15.984C22.4543 19.5396 19.6356 22.4866 15.984 22.4866C12.3324 22.4866 9.51367 19.5396 9.51367 15.984Z"
      fill="url(#paint1_linear_1055_2614)"
    />
    <path
      d="M51.8652 7.35392C49.6299 7.35392 47.3298 8.42456 46.2284 10.5009V8.78143C46.2284 7.87302 45.7424 7.38637 44.9325 7.38637C44.0579 7.38637 43.6367 7.87302 43.6367 8.78143V27.6959C43.6367 28.6043 44.1227 29.091 44.9325 29.091C45.7748 29.091 46.2284 28.5719 46.2284 27.6959V20.4935C47.4594 22.5374 49.6623 23.5756 51.8328 23.5756C55.8498 23.5756 59.6077 20.4935 59.6077 15.4972C59.6077 10.4036 55.8174 7.35392 51.8652 7.35392ZM51.5412 21.1748C48.5932 21.1748 46.1636 18.9362 46.1636 15.4972C46.1636 12.0258 48.5608 9.72229 51.5412 9.72229C54.4892 9.72229 56.9512 11.9609 56.9512 15.4972C56.9512 18.9687 54.4568 21.1748 51.5412 21.1748Z"
      fill="url(#paint2_linear_1055_2614)"
    />
    <path
      d="M69.9932 23.5756C74.4314 23.5756 78.1245 20.0393 78.1245 15.3999C78.1245 10.7929 74.399 7.35392 69.9932 7.35392C65.5874 7.35392 61.8943 10.7929 61.8943 15.3999C61.8943 20.0393 65.5874 23.5756 69.9932 23.5756ZM69.9932 21.2397C66.9804 21.2397 64.486 18.8389 64.486 15.4323C64.486 12.0582 66.948 9.72229 69.9932 9.72229C73.1356 9.72229 75.5328 12.0258 75.5328 15.4323C75.5328 18.8389 73.1032 21.2397 69.9932 21.2397Z"
      fill="url(#paint3_linear_1055_2614)"
    />
    <path
      d="M93.548 7.45125C92.7381 7.45125 92.2522 7.97035 92.2522 8.84632V16.7301C92.2522 19.3904 90.6972 21.3695 88.1055 21.3695C85.8703 21.3695 84.3153 20.2015 84.3153 17.1518V8.84632C84.3153 7.97035 83.797 7.45125 83.0195 7.45125C82.242 7.45125 81.7236 7.97035 81.7236 8.84632V17.1843C81.7236 21.4992 84.1533 23.5432 87.4576 23.5432C89.6929 23.5432 91.4423 22.5374 92.4465 20.6233V22.1806C92.4465 22.9916 93.0297 23.4134 93.6452 23.4134C94.2283 23.4134 94.8114 22.9916 94.8114 22.1806V8.84632C94.8114 7.97035 94.2931 7.45125 93.548 7.45125Z"
      fill="url(#paint4_linear_1055_2614)"
    />
    <path
      d="M107.924 7.35392C105.689 7.35392 103.389 8.42456 102.288 10.5009V8.78143C102.288 7.87302 101.802 7.38637 100.992 7.38637C100.117 7.38637 99.696 7.87302 99.696 8.78143V27.6959C99.696 28.6043 100.182 29.091 100.992 29.091C101.834 29.091 102.288 28.5719 102.288 27.6959V20.4935C103.519 22.5374 105.722 23.5756 107.892 23.5756C111.909 23.5756 115.667 20.4935 115.667 15.4972C115.667 10.4036 111.877 7.35392 107.924 7.35392ZM107.601 21.1748C104.653 21.1748 102.223 18.9362 102.223 15.4972C102.223 12.0258 104.62 9.72229 107.601 9.72229C110.549 9.72229 113.011 11.9609 113.011 15.4972C113.011 18.9687 110.516 21.1748 107.601 21.1748Z"
      fill="url(#paint5_linear_1055_2614)"
    />
    <path
      d="M132.629 7.51614C131.754 7.51614 131.301 8.06768 131.301 8.87876V10.5009C130.199 8.35967 127.996 7.35392 125.567 7.35392C121.679 7.35392 117.954 10.4036 117.954 15.3999C117.954 20.4935 121.874 23.5432 125.567 23.5432C127.931 23.5432 130.232 22.4401 131.301 20.3313V21.8886C131.301 22.8943 131.787 23.4134 132.596 23.4134C133.471 23.4134 133.892 22.8294 133.892 21.8886V8.87876C133.892 8.03524 133.406 7.51614 132.629 7.51614ZM125.988 21.2073C123.137 21.2073 120.61 18.9687 120.61 15.4323C120.61 11.9933 123.04 9.75473 125.988 9.75473C129 9.75473 131.333 11.9609 131.333 15.4323C131.333 18.9687 128.936 21.2073 125.988 21.2073Z"
      fill="url(#paint6_linear_1055_2614)"
    />
    <path
      d="M145.907 21.1099C145.68 21.1099 145 21.4668 143.995 21.4668C142.408 21.4668 141.598 20.6882 141.598 18.6118V9.56007H145.291C145.809 9.56007 146.198 9.17075 146.198 8.61922C146.198 8.06768 145.809 7.67836 145.291 7.67836H141.598V4.30424C141.598 3.42827 141.08 2.90918 140.302 2.90918C139.525 2.90918 139.006 3.46072 139.006 4.30424V7.67836H137.225C136.706 7.67836 136.318 8.06768 136.318 8.61922C136.318 9.17075 136.706 9.56007 137.225 9.56007H139.006V18.9038C139.006 22.0832 140.464 23.5756 143.283 23.5756C144.319 23.5756 145.356 23.3485 146.069 23.0241C146.457 22.7645 146.684 22.4401 146.684 21.9859C146.684 21.4344 146.328 21.1099 145.907 21.1099Z"
      fill="url(#paint7_linear_1055_2614)"
    />
    <path
      d="M161.384 19.7473C160.996 19.7473 160.639 20.0393 160.024 20.3637C159.084 20.9153 157.918 21.2721 156.557 21.2721C153.221 21.2721 150.856 19.1958 150.791 15.4648H161.19C161.903 15.4648 162.324 15.043 162.324 14.0048C162.324 9.88451 159.473 7.35392 155.715 7.35392C151.504 7.35392 148.135 10.6956 148.135 15.4648C148.135 20.2664 151.245 23.5432 156.266 23.5432C158.501 23.5432 160.445 22.8294 161.579 21.8561C162.097 21.4019 162.227 21.045 162.227 20.6557C162.227 20.2015 161.903 19.7473 161.384 19.7473ZM155.715 9.62496C158.242 9.62496 159.927 11.2796 160.121 13.5506H150.985C151.568 11.1174 153.415 9.62496 155.715 9.62496Z"
      fill="url(#paint8_linear_1055_2614)"
    />
    <path
      d="M182.297 7.35392C180.386 7.35392 178.604 8.32723 177.697 10.3063C176.887 8.26234 175.008 7.35392 173 7.35392C171.186 7.35392 169.501 8.2299 168.594 9.94939V8.87876C168.594 8.00279 168.108 7.51614 167.331 7.51614C166.553 7.51614 166.1 8.03524 166.1 8.87876V22.0508C166.1 22.9268 166.586 23.4134 167.363 23.4134C168.173 23.4134 168.659 22.9268 168.659 22.0508V13.2586H168.691C168.691 11.1174 170.343 9.59252 172.32 9.59252C174.004 9.59252 175.591 10.5658 175.591 12.9017V22.0508C175.591 22.9268 176.11 23.4134 176.887 23.4134C177.665 23.4134 178.151 22.9268 178.151 22.0508V13.2586C178.151 11.1174 179.835 9.59252 181.811 9.59252C183.464 9.59252 185.083 10.5658 185.083 12.9017V22.0508C185.083 22.9268 185.569 23.4134 186.347 23.4134C187.157 23.4134 187.643 22.9268 187.643 22.0508V12.8044C187.643 9.00854 185.181 7.35392 182.297 7.35392Z"
      fill="url(#paint9_linear_1055_2614)"
    />
    <path
      d="M200.65 7.35392C198.415 7.35392 196.115 8.42456 195.013 10.5009V8.78143C195.013 7.87302 194.527 7.38637 193.717 7.38637C192.843 7.38637 192.422 7.87302 192.422 8.78143V27.6959C192.422 28.6043 192.908 29.091 193.717 29.091C194.56 29.091 195.013 28.5719 195.013 27.6959V20.4935C196.244 22.5374 198.447 23.5756 200.618 23.5756C204.635 23.5756 208.393 20.4935 208.393 15.4972C208.393 10.4036 204.602 7.35392 200.65 7.35392ZM200.326 21.1748C197.378 21.1748 194.949 18.9362 194.949 15.4972C194.949 12.0258 197.346 9.72229 200.326 9.72229C203.274 9.72229 205.736 11.9609 205.736 15.4972C205.736 18.9687 203.242 21.1748 200.326 21.1748Z"
      fill="url(#paint10_linear_1055_2614)"
    />
    <path
      d="M218.778 23.5756C223.216 23.5756 226.909 20.0393 226.909 15.3999C226.909 10.7929 223.184 7.35392 218.778 7.35392C214.372 7.35392 210.679 10.7929 210.679 15.3999C210.679 20.0393 214.372 23.5756 218.778 23.5756ZM218.778 21.2397C215.765 21.2397 213.271 18.8389 213.271 15.4323C213.271 12.0582 215.733 9.72229 218.778 9.72229C221.921 9.72229 224.318 12.0258 224.318 15.4323C224.318 18.8389 221.888 21.2397 218.778 21.2397Z"
      fill="url(#paint11_linear_1055_2614)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1055_2614"
        x1="-1.20809"
        y1="11.8717"
        x2="140.474"
        y2="45.8914"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1055_2614"
        x1="-1.20778"
        y1="11.8719"
        x2="140.474"
        y2="45.8915"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1055_2614"
        x1="-41.6386"
        y1="3.05786"
        x2="215.701"
        y2="170.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1055_2614"
        x1="-41.6386"
        y1="3.05786"
        x2="215.701"
        y2="170.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1055_2614"
        x1="-41.6386"
        y1="3.05786"
        x2="215.701"
        y2="170.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1055_2614"
        x1="-41.6386"
        y1="3.05786"
        x2="215.701"
        y2="170.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1055_2614"
        x1="-41.6386"
        y1="3.05786"
        x2="215.701"
        y2="170.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1055_2614"
        x1="-41.6386"
        y1="3.05786"
        x2="215.701"
        y2="170.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1055_2614"
        x1="-41.6386"
        y1="3.05786"
        x2="215.701"
        y2="170.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1055_2614"
        x1="-41.6386"
        y1="3.05786"
        x2="215.701"
        y2="170.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1055_2614"
        x1="-41.6386"
        y1="3.05786"
        x2="215.701"
        y2="170.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1055_2614"
        x1="-41.6386"
        y1="3.05786"
        x2="215.701"
        y2="170.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000245549" stop-color="#FFE12B" />
        <stop offset="0.2977" stop-color="#FFA500" />
        <stop offset="0.6582" stop-color="#FF3132" />
        <stop offset="0.9993" stop-color="#FF158B" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>
<style lang="scss">
</style>