import { render, staticRenderFns } from "./AdicionarAprovador.vue?vue&type=template&id=7a86e8ae&scoped=true&"
import script from "./AdicionarAprovador.vue?vue&type=script&lang=js&"
export * from "./AdicionarAprovador.vue?vue&type=script&lang=js&"
import style0 from "./AdicionarAprovador.vue?vue&type=style&index=0&id=7a86e8ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a86e8ae",
  null
  
)

export default component.exports