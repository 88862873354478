<template>
  <div v-if="user" class="d-flex flex-row align-center ma-0">
    <v-col>
      <v-avatar class="avatarCircle" color="primary">
        <h2 v-if="user.nome" class="avatarText" style="color: #ffffff">
          {{ user.nome[0] + user.nome[1].toUpperCase() }}
        </h2>
      </v-avatar>
    </v-col>
    <v-col class="pa-0">
      <div class="d-flex align-center">
        <p class="username ma-0 primary--text">{{ user.nome }}</p>
      </div>
      <div class="d-flex align-center text_secondary--text">
        <span v-if="user.email" class="email">{{ user?.email }}</span>
        <AppMenu />
      </div>
    </v-col>
  </div>
</template>

<script>
// import router from "@/router";
import AppMenu from "./AppMenu.vue";
import store from "@/store";
import { mapActions } from "vuex";
export default {
  components: { AppMenu },

  props: {
    url: String,
  },
  data: () => ({
    show: false,
    user: store.state.users.user,
    // url: router.currentRoute.path,
  }),
  methods: {
    ...mapActions({
      logout: "users/logout",
    }),
    async signOut() {
      await this.logout();
    },
  },
};
</script>