<template>
  <v-app>
    <template v-if="this.$router.currentRoute.name !== 'login' &&
      this.$router.currentRoute.name !== 'callback'
      ">
      <AppBar></AppBar>
    </template>
    <v-main>
      <template v-if="this.$router.currentRoute.name === 'login'">
        <v-container class="pa-0 ma-0">
          <router-view />
        </v-container>
      </template>
      <template v-else>
        <v-container style="max-width: 4000px !important" class="ma-0 pt-8 px-16">
          <router-view />
        </v-container>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar/AppBar.vue";

export default {
  name: "App",
  components: {
    AppBar,
  },
  beforeCreate() {
    let theme = JSON.parse(localStorage.getItem("@socialcam/is_dark_theme"));

    if (theme === null) {
      theme = true;
    }

    this.$vuetify.theme.dark = theme;
  },
};
</script> 

<style lang='scss'>
video {
  object-fit: cover;
}

.v-text-field>.v-input__control>.v-input__slot:before {
  border-style: none;
}

.v-select>.v-input__control>.v-input__slot:before {
  border-style: none;
}

.v-textarea>.v-input__control>.v-input__slot:before {
  border-style: none;
}

.v-btn {
  color: var(--v-text_action-base) !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 0;
}

.v-btn--text {
  background: transparent !important;
}

.theme--dark.v-btn--text {
  color: #ffffff !important;
}

.theme--dark.v-btn--icon {
  color: #ffffff !important;
}

.v-application {
  font-family: "Globotipo Texto", sans-serif !important;
  background: var(--v-background-base) !important;
}

.v-card {
  background-color: var(--v-card_bg-base) !important;
}

.v-dialog {
  .v-card {
    background-color: var(--v-dialog_bg-base) !important;
  }
}

.v-data-table {
  thead {
    tr {
      background-color: var(--v-table_header-base) !important;
    }
  }

  th {
    width: 385px !important;

    span {
      color: var(--v-primary-base) !important;
      font-size: 16px !important;
      font-weight: 700;
    }
  }

  td {
    // width: 353px !important;
    min-height: 64px;
    font-weight: 400;
    color: var(--v-gray1-base) !important;
  }

  tbody {
    background: var(--v-table_body-base) !important;

    tr:hover {
      background-color: var(--v-table_hover-base) !important;
    }
  }

  .v-data-footer {
    background: var(--v-table_body-base) !important;

    .v-data-footer__select {
      .v-icon {
        color: var(--v-icon_default-base) !important;
      }

      color: var(--v-text_secondary-base) !important;

      .v-input {
        .v-input__control {
          .v-input__slot {
            .v-select__slot {
              .v-select__selections {
                .v-select__selection {
                  color: var(--v-text_secondary-base) !important;
                }
              }
            }
          }

          .v-input__slot::after {
            color: var(--v-text_secondary-base) !important;
          }

          .v-input__append-inner {
            .v-input__icon {
              i {
                color: var(--v-text_secondary-base) !important;
              }
            }
          }
        }
      }
    }

    .v-data-footer__pagination {
      color: var(--v-text_secondary-base) !important;
    }

    .v-data-footer__icons-before {
      button {
        color: var(--v-icon_default-base) !important;
      }
    }

    .v-data-footer__icons-after {
      button {
        color: var(--v-icon_default-base) !important;
      }
    }
  }
}

.v-messages {
  display: none !important;
}

.v-select-list .v-list-item--active .v-list-item__title {
  color: #ffffff !important;
}

video {
  border-radius: 4px;
}

a {
  text-decoration: none;
}

.v-input__control {
  .v-text-field__details {
    display: none !important;
  }
}

.v-container {
  max-width: 4000px !important;
}
</style>

