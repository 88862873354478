<template>
  <div>
    <v-row class="searchAddCategoria">
      <v-col class="searchWrapper">
        <v-text-field filled prepend-inner-icon="mdi-magnify" placeholder="Buscar" v-model="nome"
          background-color="input_color" class="search" label="Nome" @input="onInput">
        </v-text-field>
      </v-col>
      <v-col class="text-right">
        <AdicionarEditarCategoria :adicionar="true" :fetchData="fetchData" />
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-col>
        <v-data-table hide-default-footer :headers="headers" :items="categorias.lstResultado"
          :items-per-page="itensPorPagina" class="elevation-1 secondary mt-8" v-if="categorias" :loading="loading"
          :header-props="headerProps">
          <template v-slot:item="row">
            <tr :key="row.item.id">
              <td>{{ row.item.nome }}</td>
              <td class="d-flex align-center justify-end pa-0">
                <v-divider vertical></v-divider>
                <AdicionarEditarCategoria :editarData="row.item" :fetchData="fetchData" />
                <v-divider vertical></v-divider>

                <RemoverCategoria :id="row.item.id" :nome-categoria="row.item.nome" :deleteCategoria="deleteCategoria"
                  :fetchData="fetchData" />
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination class="mt-5" v-model="pagina" :length="totalPages" @input="handlePageChange"></v-pagination>
      </v-col>
    </v-row>

  </div>
</template>
<script>
import AdicionarEditarCategoria from "@/components/Modals/Categoria/AdicionarEditarCategoria.vue";
import RemoverCategoria from "@/components/Modals/Categoria/RemoverCategoria.vue";
import { mapActions, mapGetters } from "vuex";
import { apiClient } from "@/services/api";
export default {
  components: { AdicionarEditarCategoria, RemoverCategoria },
  data() {
    return {
      nome: "",
      loading: false,
      pagina: 1,
      itensPorPagina: 10,
      options: {},
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Ações",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ],
      headerProps: {
        mobile: true,
        checkboxColor: "success--text",
      },
      debounce: null,
    };
  },
  async created() {
    await this.fetchData();
  },
  computed: {
    ...mapGetters({
      categorias: "categorias/getterCategorias",
    }),
    filteredItems() {
      if (!this.nome.trim()) {
        return this.categorias.lstResultado;
      }
      return this.categorias.lstResultado.filter((item) => {
        return (
          item.nome && item.nome.toLowerCase().includes(this.nome.toLowerCase())
        );
      });
    },
    totalPages() {
      let pages = null;
      if (this.categorias) {
        pages = Math.ceil(this.categorias.total / this.itensPorPagina);
      }
      return pages;
    },
  },
  methods: {
    ...mapActions({
      getCategorias: "categorias/getCategorias",
      getNumCategorias: "categorias/getNumCategorias",
    }),
    async deleteCategoria(id) {
      try {
        const response = await apiClient.delete(`/categoria/${id}`);
        if (response.status === 200) {
          await this.fetchData();
        }
      } catch (error) {
        return alert(error.message);
      }
    },
    onInput() {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(async () => {
        await this.fetchData();
      }, 1500);
    },
    async fetchData() {
      try {
        this.loading = true;
        await this.getCategorias({
          nome: this.nome,
          pagina: this.pagina,
          itensPorPagina: this.itensPorPagina,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handlePageChange() {
      await this.fetchData();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
div.searchWrapper {
  align-self: flex-start;
  height: 40px;
}

.search {
  width: 100%;
  height: 100%;
}
</style>