<template>
  <div>
    <v-app-bar class="px-12" color="app_bar_color" app clipped-left height="100px">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <Logo />
        </router-link>
      </v-toolbar-title>

      <div class="d-flex align-center ml-auto">
        <FaleConosco></FaleConosco>
        <AppProfile :url="url"></AppProfile>
      </div>
    </v-app-bar>

    <v-navigation-drawer class="navDrawer" color="app_bar_color" app v-model="drawer" clipped width="300">
      <v-list v-if="url === '/'" dense class="pa-12">

        <v-list-group :append-icon="categoria.name !== 'Todas' ? 'mdi-chevron-down' : null"
          v-for="categoria in categoriasEditada" :key="categoria.name" v-model="categoria.active" color="primary"
          @click.prevent="filtraConteudo(categoria.id, null)">


          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title :class="categoria.active
                ? 'text-subtitle-1'
                : 'text-subtitle-1 text_secondary--text'
                ">
                {{ categoria.name | capitalize }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="subcategoria in categoria.subcategorias" :key="subcategoria.id_subcategoria" @click.prevent="
            filtraConteudo(
              subcategoria.id_categoria,
              subcategoria.id_subcategoria
            )
            " :class="filtros.subcategoria_id === subcategoria.id_subcategoria
    ? 'subcategoriaList active'
    : 'subcategoriaList '
    ">
            <v-list-item-content class="pl-2">
              <template v-if="subcategoria.name.length > 12">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on" style="font-size: 16px">
                      {{ subcategoria.name | capitalize }}
                    </v-list-item-title>
                  </template>
                  <span> {{ subcategoria.name }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-list-item-title style="font-size: 16px">
                  {{ subcategoria.name | capitalize }}
                </v-list-item-title>
              </template>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list class="pa-16" v-if="url.includes('/admin')" nav dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="item in adminArea" :key="item.title" :to="item.url" class="adminArea">
            <v-list-item-content>
              <v-list-item-title style="font-size: 16px">
                {{ item.title | capitalize }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <v-list class="px-11">
        <v-list-item>
          <v-switch v-model="$vuetify.theme.dark" :label="$vuetify.theme.dark ? 'Modo Claro' : 'Modo Escuro'"
            @change="setTheme" class="ma-0"></v-switch>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
    </v-navigation-drawer>
  </div>
</template>

<script>
import AppProfile from "./AppProfile.vue";
import FaleConosco from "../FaleConosco.vue";
import Logo from "../LogoIcon.vue";
import router from "@/router";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      selectedItem: -1,
      collapseOnScroll: true,
      drawer: true,
      adminArea: [
        {
          title: "Categoria",
          icon: "mdi-view-dashboard",
          url: "/admin/categorias",
        },
        {
          title: "Subcategorias",
          icon: "mdi-image",
          url: "/admin/subcategoria",
        },
        { title: "Conteúdos", icon: "mdi-help-box", url: "/admin/conteudos" },
        { title: "Parâmetros", icon: "mdi-help-box", url: "/admin/parametros" },
        { title: "Usuários", icon: "mdi-help-box", url: "/admin/usuarios" },
        {
          title: "Aprovadores",
          icon: "mdi-help-box",
          url: "/admin/aprovadores",
        },
        { title: "Relatórios", icon: "mdi-help-box", url: "/admin/relatorios" },
        { title: "Log", icon: "mdi-help-box", url: "/admin/log" },
      ],
      items: [],
      url: router.currentRoute.path,
    };
  },
  components: { AppProfile, FaleConosco, Logo },
  computed: {
    ...mapGetters({
      categorias: "categorias/getterCategoriasSubcategorias",
      filtros: "conteudos/getterFiltrosConteudo",
      cleanTag: "tags/getterCleanTagsObj"
    }),
    categoriasEditada() {
      let categoria = []
      if (this.categorias) {

        categoria = [{ id: null, name: 'Todas', subcategorias: [] }, ...this.categorias]
      }
      return categoria
    }
  },
  methods: {
    ...mapActions({
      getCategoriasSubcategorias: "categorias/getCategoriasSubcategorias",
      getConteudos: "conteudos/getConteudos",
      setFiltroCategoriaSubcategoria:
        "conteudos/setFiltroCategoriaSubcategoria",
      setPaginaConteudo: "conteudos/setPaginaConteudo",
      setCleanTag: 'tags/setCleanTag'
    }),
    async filtraConteudo(id_categoria, id_subcategoria) {
      await this.setCleanTag(!this.cleanTag)

      if (
        this.filtros.categoria_id === id_categoria &&
        id_subcategoria === null
      ) {
        return;
      }

      await this.setFiltroCategoriaSubcategoria({
        id_categoria,
        id_subcategoria,
      });

      await this.setPaginaConteudo(1);

      await this.getConteudos({
        tag: this.filtros.tag,
        categoria: id_categoria,
        subcategoria: id_subcategoria,
        status: this.filtros.status,
        praca: this.filtros.praca_id,
        pagina: 1,
        itensPorPagina: this.filtros.itensPorPagina,
      });

    },
    setTheme() {
      localStorage.setItem(
        "@socialcam/is_dark_theme",
        this.$vuetify.theme.dark
      );
    },
  },
  async created() {
    await this.getCategoriasSubcategorias();
  },
  filters: {
    capitalize(string) {
      const newString = string
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      return newString;
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.url = to.path;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.adminArea.v-list-item--active {
  .v-list-item__content {
    .v-list-item__title {
      color: var(--v-gray1-base) !important;
      font-weight: bold !important;
    }
  }
}

.theme--dark.adminArea.v-list-item--active {
  .v-list-item__content {
    .v-list-item__title {
      color: var(--v-gray1-base) !important;
    }
  }
}

.subcategoriaList.active {
  background-color: RGBA(255, 99, 28, 0.3);
}

.subcategoriaList:hover {
  background-color: RGBA(255, 99, 28, 0.3) !important;
}
</style>