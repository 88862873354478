import { apiClient } from '@/services/api'

const state = () => ({
  log: null,
  numLogs: null,
});

const getters = {
  getterLog: state => {
    return state.log;
  },
};

const actions = {
  async getLog({ commit }, payload) {
    const { pagina, itensPorPagina, nomeUsuario, funcionalidade, acao, dataInicial, dataFinal, } = payload;
    try {


      const query = `${nomeUsuario.trim() !== '' && nomeUsuario !== 'Todos' ? `&NomeUsuario=${nomeUsuario}` : ''}${funcionalidade.trim() !== '' && funcionalidade !== 'Todas' ? `&Funcionalidade=${funcionalidade}` : ''}${acao.trim() !== '' && acao !== 'Todas' ? `&Acao=${acao}` : ''}`

      const { data } = await apiClient.get(`log?Paginar=true&Pagina=${pagina}&ItensPorPagina=${itensPorPagina}&GerarPlanilha=false&DataInicio=${dataInicial.toISOString()}&DataFim=${dataFinal.toISOString()}${query}`)

      commit("setLog", data);
    } catch (error) {
      return error
    }
  },
};

const mutations = {
  setLog(state, payload) {
    state.log = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
