import router from "../../router";

const state = () => ({
  user: null,

});

const getters = {
  isAuthenticated: state => {
    return !!state.user;
  },
  username: state => {
    return state.user.nome;
  },
  email: state => {
    return state.user.email;
  },
  isAdm: state => {
    if (state.user.alcadaId === 1) {
      return true;
    }
    false;
  },
  login: state => {
    if (state.user?.login) {

      return state.user.login
    }

    return null;
  }
};

const actions = {
  getUserData({ commit }, payload) {
    commit("setUserData", payload);
  },
  logout({ commit }) {
    commit("setUserData", null);
    localStorage.clear()
    router.push("/login");
  },
};

const mutations = {
  setUserData(state, payload) {
    state.user = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
