<template>
  <div class="text-center">
    <v-dialog v-model="removerDialog" width="500" height="180">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <v-card class="card">
        <v-card-title>
          Remover aprovador
          <v-spacer></v-spacer>
          <v-btn icon @click="removerDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="cardText">
          <p>Tem certeza que deseja excluir essa aprovador?</p>
          <p>{{ nomeAprovador.toUpperCase() }}</p>
          <div class="buttonsWrapper">
            <v-btn
              @click.prevent="removerDialog = false"
              class="mx-4 cancelarButton"
              text
            >
              Cancelar
            </v-btn>
            <v-btn class="excluirButton" @click.prevent="remove">
              Excluir
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

 
<script>
export default {
  props: {
    nomeAprovador: String,
    removerAprovador: Function,
    id: Number,
  },
  data() {
    return {
      valid: false,
      title: "",
      type: "",
      removerDialog: false,
    };
  },
  methods: {
    async remove() {
      await this.removerAprovador(this.id).then(() => {
        this.removerDialog = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cardText {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--v-gray3-base);
  }
  p + p {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: var(--v-gray1-base);
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .v-btn {
    width: 110px;
    height: 36px;
  }
}

.excluirButton {
  background: var(--v-error-base) !important;
  color: var(--v-gray4-base);
  span {
    text-transform: uppercase !important;
  }
}

.cancelarButton {
  background: transparent;
}
</style>