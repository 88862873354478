<template>
  <div>
    <v-row class="d-flex flex-row">
      <v-col>
        <v-tabs center-active background-color="background">
          <v-tab @click="onChange(1)">Palavras mais buscadas</v-tab>
          <v-tab @click="onChange(2)">Relatório por conteúdo</v-tab>
          <v-tab @click="onChange(3)">Relatório por usuário</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8" md="4">
        <v-text-field background-color="input_color" prepend-inner-icon="mdi-magnify" placeholder="Buscar" filled
          v-model="texto" @input="onInput">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-menu v-model="modal_data_inicial" :close-on-content-click="false" :nudge-right="40"
          transition="scale-transition" offset-y min-width="auto" color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field filled v-model="data_inicial_formatada" label="Início" append-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on" color="primary" background-color="input_color"></v-text-field>
          </template>
          <v-date-picker v-model="data_inicial" @input="modal_data_inicial = false" event-color="primary" color="primary"
            locale="pt-Br"></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-menu v-model="modal_data_final" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
          offset-y min-width="auto" color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field filled v-model="data_final_formatada" label="Final" append-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on" background-color="input_color" color="primary"></v-text-field>
          </template>
          <v-date-picker v-model="data_final" @input="modal_data_final = false" event-color="primary"
            @change="fetchData(type)" color="primary" locale="pt-Br"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn @click.prevent="exportarExcel" color="primary">
          Exportar em excel
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <!-- Relatório de palavra mais pesquisada -->


        <v-data-table hide-default-footer v-if="relatorio && type === 1" :headers="headers"
          :items="relatorio.lstResultado" :items-per-page="itensPorPagina" :loading="loading" class="elevation-1">
          <template v-slot:item="row">
            <tr :key="row.item.id">
              <td>
                {{ row.item.palavra }}
              </td>
              <td>{{ row.item.quantidade }}</td>
            </tr>
          </template>
        </v-data-table>

        <!-- Relatório de palavra mais pesquisada -->
        <v-data-table hide-default-footer v-if="relatorio && type === 2" :headers="headers2"
          :items="relatorio.lstResultado" :loading="loading" class="elevation-1" :items-per-page="itensPorPagina">
          <template v-slot:item="row">
            <tr :key="row.item.id">
              <td>
                {{ row.item.conteudo }}
              </td>
              <td>{{ row.item.visualizacoes }}</td>
              <td>{{ row.item.downloads }}</td>
            </tr>
          </template>
        </v-data-table>

        <!-- Relatório de palavra mais pesquisada -->
        <v-data-table hide-default-footer v-if="relatorio && type === 3" :headers="headers3"
          :items="relatorio.lstResultado" :loading="loading" class="elevation-1" :items-per-page="itensPorPagina">
          <template v-slot:item="row">
            <tr :key="row.item.id">
              <td>
                {{ row.item.usuarioNome }}
              </td>
              <td>
                {{ row.item.usuarioArea }}
              </td>
              <td>
                {{ row.item.downloads }}
              </td>

            </tr>
          </template>
        </v-data-table>

        <v-pagination class="mt-5" v-model="pagina" :length="totalPages" @input="handlePageChange" :total-visible="8" />


      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import { apiClient } from "@/services/api";

export default {
  data() {
    return {
      type: 1,
      texto: "",
      loading: false,
      pagina: 1,
      itensPorPagina: 10,
      data_inicial: null,
      data_final: null,
      data_inicial_formatada: null,
      data_final_formatada: null,
      modal_data_inicial: false,
      modal_data_final: false,
      headers: [
        {
          text: "Palavra",
          align: "start",
          sortable: false,
        },
        {
          text: "Quantidade",
          align: "start",
          sortable: false,
        },
      ],
      headers2: [
        {
          text: "Conteúdo",
          align: "start",
          sortable: false,
        },
        {
          text: "Visualizações",
          align: "start",
          sortable: false,
        },
        {
          text: "Download",
          align: "start",
          sortable: false,
        },
      ],
      headers3: [
        {
          text: "Usuário",
          align: "start",
          sortable: false,
        },
        {
          text: "Área",
          align: "start",
          sortable: false,
        },
        {
          text: "Download",
          align: "start",
          sortable: false,
        },
        // {
        //   text: "Visualizações",
        //   align: "start",
        //   sortable: false,
        // },
        // {
        //   text: "Tempo total consumido",
        //   align: "start",
        //   sortable: false,
        // },
      ],
      debounce: null,
      exportar_loading: null,
    };
  },
  async created() {
    await this.fetchData(this.type);
  },
  computed: {
    ...mapGetters({
      relatorio: "relatorios/getterRelatorio",
    }),
    totalPages() {
      let pages = null;
      if (this.relatorio) {
        pages = Math.ceil(this.relatorio.total / this.itensPorPagina);
      }
      return pages;
    },
  },
  methods: {
    ...mapActions({
      getRelatorioPalavra: "relatorios/getRelatorioPalavrasMaisPesquisadas",
      getRelatorioPorConteudo: "relatorios/getRelatorioPorConteudo",
      getRelatorioPorUsuario: "relatorios/getRelatorioPorUsuario",
    }),
    async onChange(value) {
      this.type = value;
      await this.handlePageChange();
    },
    onInput() {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(async () => {
        await this.fetchData(this.type);
      }, 1500);
    },
    async handlePageChange() {
      await this.fetchData(this.type);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async fetchData(type, gerarPlanilha = false) {
      try {
        this.loading = true;
        const checkDate = this.checkDates(this.data_inicial, this.data_final);

        if (checkDate.valid !== true) {
          return this.$toast.error(checkDate.msg);
        }

        const data_hoje = new Date();
        const data_limite = new Date(
          `${data_hoje.getFullYear() - 1}/${data_hoje.getMonth() + 1
          }/${data_hoje.getDate()}`
        ).toISOString();

        const dataInicial =
          this.data_inicial !== null
            ? new Date(this.data_inicial)
            : new Date(data_limite);
        const dataFinal =
          this.data_final !== null
            ? new Date(this.data_final)
            : data_hoje;

        dataFinal.setHours(20, 59, 0, 0)

        if (type === 1) {
          this.debounce = setTimeout(async () => {
            await this.getRelatorioPalavra({
              palavra: this.texto,
              dataInicial,
              dataFinal,
              pagina: this.pagina,
              itensPorPagina: this.itensPorPagina,
              gerarPlanilha,
            }).then(() => {
              this.loading = false
            })
          }, 1500)
        } else if (type === 2) {
          this.debounce = setTimeout(async () => {
            await this.getRelatorioPorConteudo({
              nome: this.texto,
              dataInicial,
              dataFinal,
              pagina: this.pagina,
              itensPorPagina: this.size ? this.size : 100,
              gerarPlanilha,
            }).then(() => {
              this.loading = false
            })
          }, 1500);
        } else {
          this.debounce = setTimeout(async () => {
            await this.getRelatorioPorUsuario({
              login: this.texto,
              dataInicial,
              dataFinal,
              pagina: this.pagina,
              itensPorPagina: this.size ? this.size : 100,
              gerarPlanilha,
            }).then(() => {
              this.loading = false
            })
          }, 1500);
        }
      } catch (error) {
        console.log(error)
      }
    },

    async exportarExcel() {
      try {
        this.exportar_loading = false;

        let item_name;
        let response;
        const data_hoje = new Date();

        const data_limite = new Date(
          `${data_hoje.getFullYear() - 1}/${data_hoje.getMonth() + 1
          }/${data_hoje.getDate()}`
        ).toISOString();

        const checkDate = this.checkDates(this.data_inicial, this.data_final);

        if (checkDate.valid !== true) {
          return this.$toast.error(checkDate.msg);
        }

        const dataInicial =
          this.data_inicial !== null
            ? new Date(this.data_inicial)
            : new Date(data_limite);
        const dataFinal =
          this.data_final !== null
            ? new Date(this.data_final)
            : data_hoje;

        dataFinal.setHours(20, 59, 0, 0)

        if (this.type === 1) {
          item_name = "Relatorio_por_palavras_mais_pesquisadas";

          response = await apiClient.get(
            `relatorio/getpalavrasmaispesquisadas?GerarPlanilha=${true}${this.texto.trim() !== "" ? `&Palavra=${this.texto}` : ""
            }${`&DataInicio=${dataInicial.toISOString()}`}${`&DataFim=${dataFinal.toISOString()}`}`,
            { responseType: "blob" }
          );
        } else if (this.type === 2) {
          item_name = "Relatorio_por_conteudo";
          response = await apiClient.get(
            `relatorio/getrelatorioporconteudo?GerarPlanilha=${true}&Paginar=false${this.texto.trim() !== "" ? `&Palavra=${this.texto}` : ""
            }${`&DataInicio=${dataInicial.toISOString()}`}${`&DataFim=${dataFinal.toISOString()}`}`,
            { responseType: "blob" }
          );
        } else {
          item_name = "Relatorio_por_usuario";
          response = await apiClient.get(
            `relatorio/getrelatorioporusuario?GerarPlanilha=${true}${this.texto.trim() !== "" ? `&Palavra=${this.texto}` : ""
            }${`&DataInicio=${dataInicial.toISOString()}`}${`&DataFim=${dataFinal.toISOString()}`}`,
            { responseType: "blob" }
          );
        }

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = item_name;
        link.click();
        link.remove();

        if (response.status === 200) {
          return this.$toast.success("Relatório exportado com sucesso!");
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.exportar_loading = true;
      }
    },

    checkDates(data_inicio, data_fim) {
      if (data_inicio === null && data_fim === null) {
        return {
          valid: true,
        };
      }

      if (data_inicio === null && data_fim !== null) {
        return {
          valid: false,
          msg: "Selecione uma data inicial.",
        };
      }

      if (data_inicio !== null && data_fim === null) {
        return {
          valid: false,
          msg: "Selecione uma data final.",
        };
      }

      const data_inicial = dayjs(data_inicio);
      const data_final = dayjs(data_fim);
      const data_hoje = dayjs();
      const diff = dayjs(data_final).diff(data_inicial, "days");

      if (diff > 365) {
        return {
          valid: false,
          msg: "A diferença entre a data inicial e a data final deve ser no máximo de 6 meses.",
        };
      }

      if (data_inicial > data_final) {
        return {
          valid: false,
          msg: "A data inicial não pode ser maior que a data final!",
        };
      }

      if (data_inicial > data_hoje) {
        return {
          valid: false,
          msg: "A data inicial não pode ser maior que a data atual!",
        };
      }

      if (data_final > data_hoje) {
        return {
          valid: false,
          msg: "A data final não pode ser maior que a data atual!",
        };
      }

      return {
        valid: true,
      };
    },
  },
  watch: {
    data_inicial(val) {
      console.log(this.data_inicial, val);
      this.data_inicial_formatada = dayjs(val).format("DD/MM/YYYY");
    },
    data_final(val) {
      console.log(this.data_final, val);
      this.data_final_formatada = dayjs(val).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: none !important;
}

table {
  th {
    width: 385px !important;
    height: 72px !important;

    span {
      color: var(--v-primary-base) !important;
      font-size: 16px !important;
      font-weight: 700;
    }
  }

  td {
    // width: 353px !important;
    min-height: 64px;
    font-weight: 400;
    color: var(--v-text_primary-base) !important;
  }

  tbody,
  .v-data-footer {
    background: var(--v-table_body-base) !important;
  }
}
</style>