import { apiClient } from '@/services/api'

const state = () => ({
  relatorio: null,
});

const getters = {
  getterRelatorio: state => {
    return state.relatorio;
  },
};

const actions = {
  async getRelatorioPalavrasMaisPesquisadas({ commit }, payload) {
    const {
      palavra,
      dataInicial,
      dataFinal,
      pagina,
      itensPorPagina,
      gerarPlanilha
    } = payload

    try {
      let response

      if (palavra.trim() === "") {
        response = await apiClient.get(`relatorio/getPalavrasMaisPesquisadas?DataInicio=${dataInicial.toISOString()}&DataFim=${dataFinal.toISOString()}&Pagina=${pagina}&ItensPorPagina=${itensPorPagina}&GerarPlanilha=${gerarPlanilha}`)
      } else {
        response = await apiClient.get(`relatorio/getPalavrasMaisPesquisadas?Palavra=${palavra}&DataInicio=${dataInicial.toISOString()}&DataFim=${dataFinal.toISOString()}&Pagina=${pagina}&ItensPorPagina=${itensPorPagina}&GerarPlanilha=${gerarPlanilha}`)
      }

      commit("setRelatorio", response.data);
    } catch (error) {
      return error
    }
  },

  async getRelatorioPorConteudo({ commit }, payload) {
    try {
      const {
        nome,
        dataInicial,
        dataFinal,
        pagina,
        itensPorPagina,
        gerarPlanilha
      } = payload

      let response
      if (nome.trim() === "") {
        response = await apiClient.get(`relatorio/getRelatorioPorConteudo?&DataInicio=${dataInicial.toISOString()}&DataFim=${dataFinal.toISOString()}&Pagina=${pagina}&ItensPorPagina=${itensPorPagina}&GerarPlanilha=${gerarPlanilha}`)
      } else {
        response = await apiClient.get(`relatorio/getRelatorioPorConteudo?Nome=${nome}&DataInicio=${dataInicial.toISOString()}&DataFim=${dataFinal.toISOString()}&Pagina=${pagina}&ItensPorPagina=${itensPorPagina}&GerarPlanilha=${gerarPlanilha}`)

      }

      commit("setRelatorio", response.data);
    } catch (error) {
      return error
    }
  },

  async getRelatorioPorUsuario({ commit }, payload) {
    try {
      const {
        login,
        dataInicial,
        dataFinal,
        pagina,
        itensPorPagina,
        gerarPlanilha
      } = payload
      let response
      if (login.trim() === "") {
        response = await apiClient.get(`relatorio/getRelatorioPorUsuario?DataInicio=${dataInicial.toISOString()}&DataFim=${dataFinal.toISOString()}&Pagina=${pagina}&ItensPorPagina=${itensPorPagina}&GerarPlanilha=${gerarPlanilha}`)
      } else {
        response = await apiClient.get(`relatorio/getRelatorioPorUsuario?Login=${login}&DataInicio=${dataInicial.toISOString()}&DataFim=${dataFinal.toISOString()}&Pagina=${pagina}&ItensPorPagina=${itensPorPagina}&GerarPlanilha=${gerarPlanilha}`)
      }
      commit("setRelatorio", response.data);
    } catch (error) {
      return error
    }
  },

};

const mutations = {
  setRelatorio(state, payload) {
    state.relatorio = payload;
  },
  setNumRelatorio(state, payload) {
    state.numRelatorio = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};