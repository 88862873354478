<template>
  <div id="callback"><LoadingSpinner /></div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import { mapActions } from "vuex";
import { apiClient } from "@/services/api";

export default {
  components: { LoadingSpinner },
  async mounted() {
    let params = this.getParamsURLCallback();
    console.log(params);
    await this.auth_request(params);
  },
  methods: {
    ...mapActions({
      auth_request_token: "AUTH_REQUEST_TOKEN",
      getUserData: "users/getUserData",
      getUserEmail: "users/getUserEmail",
    }),
    getParamsURLCallback() {
      var url = window.location.href;
      var params = Object.fromEntries(
        new URLSearchParams(url.split("?").pop())
      );
      return params;
    },
    async auth_request(params) {
      try {
        //const response = await this.auth_request_token(params);

        const response = await apiClient.post("/auth?code=" + params.code);
        if (response.status === 200) {
          await this.getUserData(response.data);

          return this.$router.push("/");
        }

        this.$toast.error("Usuário não autenticado.");
        this.$router.push("/login");
      } catch (error) {
        this.$toast.error("Usuário não autenticado.");
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#callback {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
</style>
