<template>
  <div class="loginWrapper">
    <form action="">
      <div class="mb-4">
        <v-img src="@/assets/logo.png" />
      </div>
      <button class="my-4 loginButton" @click.prevent="login">
        <template v-if="loading">
          <LoadingSpinner />
        </template>
        <template v-else>
          <v-btn class="entrarButton">Entrar</v-btn>
        </template>
      </button>
      <button text class="helpButton" @click.prevent="showModal">
        Precisa de ajuda para entrar?
      </button>
    </form>
    <ModalAjuda v-if="isModalVisible" @close="closeModal" />
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";
import LoadingSpinner from "../components/LoadingSpinner";
import ModalAjuda from "../components/Modals/ModalAjuda.vue";
export default {
  name: "LoginView",
  components: { LoadingSpinner, ModalAjuda },
  data() {
    return {
      loading: false,
      username: "",
      password: "",
      isModalVisible: false,
    };
  },
  methods: {
    ...mapActions({
      loginCallBack: "AUTH_REQUEST",
    }),
    async login() {
      try {
        this.loading = true;

        this.loginCallBack();
      } catch (error) {
        console.log("error");
        this.$toast.error("Usuário não autenticado.");
        return;
      } finally {
        this.loading = false;
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  computed: {},
};
</script>
  
  <style lang="scss" scoped>
.loginWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  form {
    width: 100%;
    max-width: 25.625rem;
    height: 17.058125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.helpButton {
  white-space: nowrap;
  color: var(--v-gray-base);
  background: transparent;
  font-size: 0.875rem;
  font-size: 14px;
  line-height: 20px;
}
.entrarButton {
  width: 110px;
  height: 36px;
  background-color: var(--v-primary-base) !important;
}
</style>
  