<template>
  <v-dialog v-model="dialog" max-width="500" max-height="180">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="addAprovadorButton" v-bind="attrs" v-on="on">
        Adicionar aprovador
      </v-btn>
    </template>

    <v-card class="addAprovadorCard">
      <v-card-title class="mb-8">
        Adicionar Aprovador
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <v-form ref="form" lazy-validation style="width: 100%">
          <v-text-field background-color="input_color" filled label="Usuário do aprovador *" v-model="nome">
          </v-text-field>
          <div class="buttonsWrapper mt-10">
            <v-btn @click.prevent="dialog = false" class="mr-8" text>
              CANCELAR
            </v-btn>

            <v-btn :loading="loading" color="primary" @click.prevent="inserirAprovador">
              SALVAR
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

 
<script>
import { apiClient } from "@/services/api";
export default {
  props: {
    adicionar: { type: Boolean, required: false },
    alcadaId: Number,
    fetchData: Function,
  },
  data() {
    return {
      nome: "",
      dialog: false,
      loading: false,
    };
  },
  methods: {
    async inserirAprovador() {
      try {
        this.loading = true;

        if (this.nome.trim() === "") {
          return this.$toast.error("Informe o nome do aprovador");
        }

        if (this.alcadaId === 0) {
          this.dialog = false;
          this.nome = "";
          return this.$toast.error("Selecione um  perfil antes de inserir um Aprovador");
        }

        const response = await apiClient.post(`/aprovadorperfil`, {
          nomeUsuario: this.nome,
          alcadaId: this.alcadaId,
          status: 1,
        });

        if (response.status === 200) {
          await this.fetchData();
          this.dialog = false;
          this.nome = "";
          return;
        }
      } catch (error) {
        console.log(error);
        return this.$toast.error(
          "Não foi possível inserir o usuário como aprovador."
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addAprovadorButton {
  background: var(--v-primary-base) !important;
  color: var(--v-gray4-base);

  span {
    text-transform: uppercase;
  }
}

.cancelarButton {
  background: transparent;
}

.addAprovadorCard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .v-btn {
    width: 110px;
    height: 36px;
  }
}
</style>