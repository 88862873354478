<template>
  <v-dialog v-model="removerDialog" width="500" height="180">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="mb-2">
        Excluir subcategoria
        <v-spacer></v-spacer>
        <v-btn icon @click="removerDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="cardText">
        <p>Tem certeza que deseja excluir essa subcategoria?</p>
        <p>{{ nomeSubcategoria.toUpperCase() }}</p>
        <div class="buttonsWrapper">
          <v-btn @click.prevent="removerDialog = false" text> Cancelar </v-btn>
          <v-btn class="excluirButton" @click.prevent="confirmar">
            Excluir
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

 
<script>
export default {
  props: {
    id: { type: Number, required: true },
    nomeSubcategoria: { type: String, required: true },
    deleteSubCategoria: { type: Function, required: true },
  },
  data() {
    return {
      valid: false,
      title: "",
      type: "",
      removerDialog: false,
    };
  },
  methods: {
    async confirmar() {
      await this.deleteSubCategoria(this.id);
      this.removerDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cardText {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--v-gray3-base);
  }
  p + p {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: var(--v-gray1-base);
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .v-btn {
    width: 110px;
    height: 36px;
  }
}

.excluirButton {
  background: var(--v-error-base) !important;
  color: var(--v-gray4-base);
  span {
    text-transform: uppercase !important;
  }
}

.cancelarButton {
  background: transparent;
}
</style>