import { apiClient } from '@/services/api'

const state = () => ({
  categorias: null,
  categorias_subcategorias: null,
});

const getters = {
  getterCategorias: state => {
    return state.categorias;
  },
  getterCategoriasSubcategorias: state => {
    return state.categorias_subcategorias;
  },
};

const actions = {
  async getCategorias({ commit }, payload) {
    try {
      const { pagina, itensPorPagina, nome } = payload
      let response
      if (nome.trim() === '') {
        response = await apiClient.get(`categoria?Pagina=${pagina}&ItensPorPagina=${itensPorPagina}`)
      } else {
        response = await apiClient.get(`categoria?Pagina=${pagina}&ItensPorPagina=${itensPorPagina}&Nome=${nome}`)
      }

      commit("setCategorias", response.data);
    } catch (error) {
      return error
    }
  },

  async getCategoriasSubcategorias({ commit }) {
    let categorias = [];
    await apiClient.get("/categoria/getLeftBar?OrderBy=name desc").then((response) => {
      response.data.map((data) => {
        const categoria = {
          id: data.id,
          name: data.name,
          subcategorias: [],
        };

        data.children.map((subcategoria) => {
          const subcategoriaData = {
            name: subcategoria.name,
            id_subcategoria: subcategoria.id,
            id_categoria: categoria.id,
          };

          categoria.subcategorias.push(subcategoriaData);
        });
        categorias.push(categoria);
      });
      commit("setCategoriasSubcategorias", categorias);
    });
  },
};

const mutations = {
  setCategorias(state, payload) {
    state.categorias = payload;
  },
  setCategoriasSubcategorias(state, payload) {
    state.categorias_subcategorias = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
