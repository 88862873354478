<template>
  <div>
    <v-row>
      <v-col>

        <TagsCombobox v-model="tagsInput" :items="tags" :editModel="editModel" :label="'Busque por tags'"
          @change="updateTagsObject" :editar="true" />
      </v-col>
      <v-col class="text-right" v-if="isAdm">
        <AddConteudo />
      </v-col>
    </v-row>


    <v-row v-if="conteudo">
      <div class="videoWrapper col">
        <VideoCard class="videoCard" :key="item.id" v-for="item in conteudo" :item="item" :fetchData="fetchData"
          :changeEditModel="changeEditModel" />
      </div>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination v-if="totalPages" v-model="pagina" :length="totalPages" @input="handlePageChange" total-visible="10"
          class="pagination"></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VideoCard from "@/components/VideoCard.vue";
import AddConteudo from "@/components/Modals/Conteudo/AddConteudo.vue";
import TagsCombobox from "@/components/Modals/Conteudo/TagsCombobox.vue"
import { mapActions, mapGetters } from "vuex";
import { apiClient } from "@/services/api";
export default {
  components: { VideoCard, AddConteudo, TagsCombobox },
  data() {
    return {
      pagina: 1,
      tag: "",
      debounce: null,
      tagsInput: null,
      tagsObj: [],
      loading: false,
      editModel: null,
      itensPorPagina: 40,
      debounceTag: null
    };
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    changeEditModel(value) {
      this.editModel = value
    },
    async updateTagsObject(values) {
      this.tagsObj = []

      values.map(value => {
        this.tagsObj.push(value.nome)
      })

      await this.onInput()
    },
    ...mapActions({
      getConteudos: "conteudos/getConteudos",
      setPaginaConteudo: "conteudos/setPaginaConteudo",
      getTags: "tags/getTags",
    }),
    async fetchData() {
      try {

        await this.getConteudos({
          tag: this.tagsObj,
          categoria: this.filtros.categoria_id,
          subcategoria: this.filtros.subcategoria_id,
          status: this.status,
          praca: this.praca_id,
          pagina: this.filtros.pagina,
          itensPorPagina: this.itensPorPagina,
        });
      } catch (error) {
        console.log(error)
      }
    },
    async handlePageChange() {
      await this.setPaginaConteudo(this.pagina);
      await this.fetchData();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onInput() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {

        const promise = this.tagsObj.map(tag =>
          apiClient.post("/palavrasbuscadas", {
            data: new Date().toISOString(),
            palavra: tag,
          })
        )

        await Promise.all(promise).then(async () => {

          await this.fetchData();
        })


      }, 1500);
    },
  },
  computed: {
    ...mapGetters({
      conteudo: "conteudos/getterConteudos",
      total_conteudos: "conteudos/getterNumConteudos",
      filtros: "conteudos/getterFiltrosConteudo",
      tags: "tags/getterTags",
      isAdm: "users/isAdm"
    }),
    totalPages() {
      let pages = 0;
      if (this.conteudo) {
        pages = Math.ceil(this.total_conteudos / this.filtros.itensPorPagina);
      }
      return pages;
    },
  },
  watch: {
    '$store.state.conteudos.filtros.pagina'(value) {
      this.pagina = value
    },
    async tagsInput() {
      await this.getTags({
        nome: this.tagsInput || "",
        pagina: 1,
        itensPorPagina: 100,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@media (max-width: 1024px) {
  .videoWrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    gap: 1rem;
  }
}

.videoWrapper {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
}

.pagination {
  display: block;

  ul.v-pagination {
    display: block !important;

    li {
      button.v-pagination__item--active {
        color: #171717 !important;
      }
    }
  }
}
</style>