<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition" persistent>
    <v-card class="loadingCard d-flex justify-center align-center">

      <v-btn :loading="true" text color="primary"></v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    loading: Boolean
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    toggleLoadingModal() {
      this.dialog = !this.dialog
    }
  }
}
</script>

<style lang="scss">
.loadingCard {
  background: rgb(33, 33, 33);
  opacity: 0.46
}
</style>