import { apiClient } from '@/services/api'

const state = () => ({
  parametros: null,
});

const getters = {
  getterParametros: state => {
    return state.parametros;
  },
};

const actions = {
  async getParametros({ commit }) {
    try {
      const { data } = await apiClient.get('parametro')

      commit("setParametros", data);
    } catch (error) {
      return error
    }
  },
};

const mutations = {
  setParametros(state, payload) {
    state.parametros = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
