<template>
  <v-combobox v-model="model" :hide-no-data="!search" :items="items" item-text="nome" hide-selected no-filter
    :search-input.sync="search" :label="label" multiple chips small-chips filled background-color="input_color" class="">
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading mr-2">Inserir</span>
        <v-chip color="primary" label small class="">
          {{ search }}
        </v-chip>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip v-if="item === Object(item)" v-bind="attrs" color="primary" :input-value="selected" label small
        text-color="#171717">
        <span class="pr-2" style="font-weight: bold">
          {{ item.nome }}
        </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: {
    items: Array,
    editModel: Array,
    editar: { type: Boolean, default: false },
    label: { type: String, required: false, default: "Insira uma tag" }
  },
  data: () => ({
    activator: null,
    attach: null,
    search: null,
    model: null,
    debounce: null
    // tags: [],
  }),

  watch: {
    model(val, prev) {
      let tags = [];
      if (val && prev && (JSON.stringify(val) === JSON.stringify(prev))) return;

      if (val !== null) {

        this.model = val.map((item) => {
          // let t = item;

          if (typeof item === "string") {
            item = {
              id: 0,
              nome: item,
              idConteudo: 0,
              criadoPor: this.$store.state.users.user.id,
              alteradoPor: this.$store.state.users.user.id,
              status: 1,
              criadoEm: new Date(),
              alteradoEm: new Date(),
            };
          }

          tags.push(item);
          return item;
        });
      }

      this.$emit("change", tags);
    },
    editModel() {
      if (this.editar === true) {
        console.log(this.model)
        this.model = this.editModel;
      }
    },
    search(value) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.$emit("input", value);
      }, 1000);
    },
    '$store.state.tags.limparTagsObj'() {
      this.activator = null
      this.attach = null
      this.search = null
      this.model = null
      this.debounce = null
    },
  },

};
</script>