<template>
  <div>
    <v-row class="my-2 search">
      <v-col cols="12" sm="10" md="5">
        <v-text-field prepend-inner-icon="mdi-magnify" placeholder="Buscar" filled background-color="input_color"
          v-model="nomeUsuario" @input="onInput">
        </v-text-field>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-col>
        <v-data-table v-if="usuarios" hide-default-footer :headers="headers" :items="usuarios.lstResultado"
          class="elevation-1 secondary" :loading="loading" :header-props="headerProps" :items-per-page="itensPorPagina">
          <template v-slot:item="row">
            <tr :key="row.item.id">
              <td>
                {{ row.item.alcadaNome.toUpperCase() }}</td>
              <td>
                {{ row.item.nome }}
              </td>
              <td>
                {{ row.item.login }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination class="mt-5" v-model="pagina" :length="totalPages" @input="handlePageChange" :total-visible="8" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      pagina: 1,
      itensPorPagina: 10,
      nomeUsuario: "",
      headers: [
        {
          text: "Perfil",
          align: "start",
          sortable: false,
        },
        {
          text: "Nome",
          align: "start",
          sortable: false,
        },
        {
          text: "Login",
          align: "start",
          sortable: false,
        },
      ],

      headerProps: {
        mobile: true,
        checkboxColor: "success--text",
      },
    };
  },
  async created() {
    await this.fetchData()
  },
  computed: {
    ...mapGetters({
      usuarios: "users_table/getterUsuarios",
    }),

    totalPages() {
      let pages = null;
      if (this.usuarios) {
        pages = Math.ceil(this.usuarios.total / this.itensPorPagina);
      }

      return pages;
    },
  },
  methods: {
    onInput() {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(async () => {
        await this.fetchData();
      }, 1500);
    },
    ...mapActions({
      getUsuarios: "users_table/getUsuarios",
    }),
    async fetchData() {
      try {
        this.loading = true
        await this.getUsuarios({
          usuario: this.nomeUsuario,
          login: this.nomeUsuario,
          pagina: this.pagina,
          itensPorPagina: this.itensPorPagina,
        });
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }

    },
    async handlePageChange() {
      await this.fetchData();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>