import { apiClient } from '@/services/api'

const state = () => ({
  subcategorias: null,
});

const getters = {
  getterSubcategorias: state => {
    return state.subcategorias;
  },
};

const actions = {
  async getSubCategorias({ commit }, payload) {
    const { pagina, itensPorPagina, nome, categoria_id } = payload


    const query = `${nome.trim() !== '' ? `&Nome=${nome}` : ''}${categoria_id !== null ? `&IdCategoria=${categoria_id}` : ''}`
    await apiClient.get(`/subcategoria?Pagina=${pagina}&ItensPorPagina=${itensPorPagina}${query}`)
      .then((response) => {
        commit("setSubCategorias", response.data);
      })
  },


};

const mutations = {
  setSubCategorias(state, payload) {
    state.subcategorias = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
