<template>
  <div class="text-right">
    <v-dialog v-model="dialog" max-width="720" max-height="732">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon>mdi-magnify </v-icon>
        </v-btn>
      </template>

      <v-card mx-auto class="card">
        <v-toolbar>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-card-title primary-title class="justify-center">
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <template v-slot:extension>
            <v-tabs v-model="tab" class="tabs ml-4">
              <v-tab
                v-for="tab in tabs"
                :key="tab"
                @click="setActivateTabName(capitalizeString(tab))"
                class="tabText mx-1"
              >
                {{ tab }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <!-- <v-spacer></v-spacer> -->
        <v-tabs-items class="ml-1" v-model="tab">
          <v-tab-item v-for="tab in tabs" :key="tab">
            <v-card flat>
              <v-card-text v-if="activeTabName === 'Conteúdos'">
                <v-text-field
                  v-model="conteudo.numDownloads"
                  label="Número de downloads"
                  clearable
                  clear-icon=""
                  readonly
                  filled
                  background-color="input_color"
                  class="textField"
                ></v-text-field>
                <v-text-field
                  v-model="conteudo.tempoMaximo"
                  label="Tempo máximo de um ASSET no Portal"
                  clearable
                  clear-icon=""
                  readonly
                  filled
                  background-color="input_color"
                ></v-text-field>
                <v-text-field
                  v-model="conteudo.path"
                  label="Path do diretório de entrada dos vídeos na rede corporativa"
                  clearable
                  clear-icon=""
                  readonly
                  filled
                  background-color="input_color"
                ></v-text-field>
                <!-- <p mb-0>Número de downloads: <span class="boldText">{{ parametro.maximoDownloads }}</span></p> -->
                <!-- <p mb-0>Tempo máximo de um ASSET no Portal: <span class="boldText">{{ parametro.tempoMaximo }}</span></p> -->
                <!-- <p mb-0>Path do diretório de entrada dos vídeos na rede Corporativa: <span class="boldText">{{ parametro.path }}</span></p> -->
              </v-card-text>
              <v-card-text class="ml-6" v-if="activeTabName === 'Feedback'">
                <span class="cardTextSpan"
                  >Destinatário do feedback:
                  {{ conteudo.destinatarioFeedback }}</span
                >
              </v-card-text>
              <v-card-text
                class="ml-6"
                v-if="activeTabName === 'Envio de link'"
              >
                <span class="cardTextSpan"
                  >Assunto: {{ conteudo.assuntoEnvioLink }}</span
                >
                <span class="cardTextSpan"
                  >Mensagem: {{ conteudo.mensagemEnvioLink }}</span
                >
              </v-card-text>
              <v-card-text class="ml-6" v-if="activeTabName === 'SMTP'">
                <span class="cardTextSpan">Host: {{ conteudo.smtpHost }}</span>
                <span class="cardTextSpan"
                  >E-mail: {{ conteudo.smtpEmail }}</span
                >
                <span class="cardTextSpan"
                  >Usuário: {{ conteudo.smtpUsuario }}</span
                >
                <span class="cardTextSpan"
                  >Senha: {{ conteudo.smtpSenha }}</span
                >
                <span class="cardTextSpan"
                  >Porta: {{ conteudo.smtpPorta }}</span
                >
                <span class="cardTextSpan">SSL: {{ conteudo.smtpSSL }}</span>
              </v-card-text>
              <v-card-text class="ml-6" v-if="activeTabName === 'Alertas'">
                <span class="cardTextSpan"
                  >Destinatário do alerta:
                  {{ conteudo.destinatarioAlerta }}</span
                >
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>
   
  <script>
export default {
  props: {
    parametro: Object,
  },
  data() {
    return {
      tabConteudo: false,
      dialog: false,
      tabs: ["Conteúdos", "Feedback", "Envio de link", "SMTP", "Alertas"],
      tab: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      activeTabName: null,
      conteudo: {
        numDownloads: this.parametro.maximoDownloads,
        tempoMaximo: this.parametro.tempoMaximo,
        path: this.parametro.path,
        destinatarioFeedback: this.parametro.destinatarioFeedback,
        assuntoEnvioLink: this.parametro.assuntoEnvioLink,
        mensagemEnvioLink: this.parametro.mensagemEnvioLink,
        smtpHost: this.parametro.smtpHost,
        smtpPorta: this.parametro.smtpPorta,
        smtpSSL: this.parametro.smtpSSL ? "Sim" : "Não",
        smtpSenha: this.parametro.smtpSenha,
        smtpUsuario: this.parametro.smtpUsuario,
        smtpEmail: this.parametro.smtpEmail,
        destinatarioAlerta: this.parametro.destinatarioAlerta,
      },
    };
  },
  mounted() {
    this.activeTabName = this.tabs[0];
  },
  created() {},
  methods: {
    setActivateTabName(name) {
      this.activeTabName = name;
    },
    capitalizeString(string) {
      return string[0].toUpperCase() + string.slice(1);
    },
  },
  watch: {
    tab() {},
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: flex-start;
  justify-content: center;
}
.boldText {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
.tabText {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  // color: #A1A1A3;
  color: var(--v-primary-base);
}
.tabText:active {
  font-weight: 700 !important;
  color: var(--v-primary-base) !important;
}
.tabs {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

p {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 0;
  margin-bottom: 9px;
}

.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.v-text-field {
  width: 95%;
  align-self: center;
  // background-color: var(--v-secondary-base);
  border: none;
  outline: none;
}

.v-label {
  font-size: 16px;
  color: blueviolet;
}

.textField.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.textField.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.cardTextSpan {
  font-size: 1.2em;
  font-weight: 500;
  padding: 4px;
}
</style>