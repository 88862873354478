<template>
  <v-dialog v-model="dialog" max-width="750" max-height="700" ref="videoPlayer" v-if="item" :key="item.id"
    @click:outside="closeVideoRelacionadoDialog" @keydown.esc="closeVideoRelacionadoDialog">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeVideoRelacionadoDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="item">
        <v-row>
          <v-col>
            <video ref="video_relacionado_player" v-if="item.video_url_low" width="700" height="600" controls
              controlsList="nodownload" @play="onPlay">
              <source :src="item.video_url_low" type="video/mp4" />
            </video>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center">
            <v-btn v-if="!loading" class="d-flex align-center" small @click.prevent="downloadVideo(item, false)"
              color="#FFFFFF" text>
              <v-icon class="pr-1">mdi-download</v-icon>
              Download
            </v-btn>
            <progress class="ml-auto" v-if="loading" :max="total" :value="loaded"></progress>
            <span class="mx-2" v-if="loading">{{ percentage }}%</span>
            <div style="max-width: 250px; width: 100%" class="mx-2">
              <v-select :background-color="$vuetify.theme.dark ? 'input_color' : '#EEEEEE'
                " v-model="id_praca" :items="pracaOptions" item-text="name" item-value="id" label="Exportar para" dense
                filled color="primary" />
            </div>
            <v-btn @click.prevent="exportarVideo(item, id_praca)" slot="end" text class="pa-0" min-width="0">
              <v-icon color="primary" class="pr-1">mdi-send</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';

export default {
  name: "VideoSelecionado",
  props: {
    item: Object,
    exportarVideo: Function,
    incrementarVisualizacao: Function,
    incrementarDownloadVisualizacao: Function
  },

  data() {
    return {
      dialog: false,
      pracaOptions: [
        { id: 1, name: "PAMRJ-MAG" },
        { id: 2, name: "PAMRJ-HN" },
        { id: 3, name: "PAM-BSB" },
        { id: 4, name: "PAM-SP" },
        { id: 5, name: "PAM-BH" },
        { id: 6, name: "VIZ-ESPORTE" },
        
      ],
      id_praca: null,
      loaded: 0,
      total: 0,
      loading: false
    };
  },
  methods: {
    openVideoRelacionadoDialog() {
      this.dialog = true;
    },
    closeVideoRelacionadoDialog() {

      if (this.$refs.video_relacionado_player) {
        this.$refs.video_relacionado_player.pause();
        this.$refs.video_relacionado_player.currentTime = 0;
      }
      this.dialog = false;
    },
    async onPlay() {
      await this.incrementarVisualizacao(this.item, true)
    },
    async downloadVideo(item, update = true) {
      try {
        if (item.nrDownload > item.maxDownload) {
          return this.$toast.info(
            "O número de downloads permitidos para esse vídeo já encerrou."
          );
        }

        const instance = axios.create();

        instance.defaults.onDownloadProgress = progressEvent => {
          this.loaded = progressEvent.loaded;
          this.total = progressEvent.total;
        };

        this.loading = true;
        await instance({
          url: item.video_url_download,
          method: "GET",
          responseType: "blob",

        })
          .then((response) => {
            let nome_download
            const href = URL.createObjectURL(response.data);
            const link = document.createElement("a");

            if (item.nome.includes('.mxf')) {
              nome_download = item.nome
            } else {
              nome_download = `${item.nome}.mxf`
            }

            console.log(nome_download)

            link.href = href;
            link.download = `${nome_download}`;
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(href);
          })
          .then(async () => {
            await this.incrementarDownloadVisualizacao(item, update);
          });
      } catch (error) {
        console.log(error)
        this.$toast.error(
          "Não foi possível iniciar o download, tente novamente."
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    percentage() {
      if (this.total === 0) {
        return 0;
      } else {
        return Math.round((this.loaded / this.total) * 100);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
progress::-moz-progress-bar {
  background: var(--v-primary-base);
}

progress::-webkit-progress-value {
  background: var(--v-primary-base);
}

progress {
  color: var(--v-primary-base);
}
</style>