<template>
  <div>
    <v-row class="mt-6">
      <v-col>
        <v-data-table v-if="parametros" :headers="headers" :items="Array(parametros)" :loading="loading"
          class="elevation-1 secondary" :header-props="headerProps" :footer-props="footerProps">
          <template v-slot:item="row">
            <tr :key="row.item.id">
              <td>{{ row.item.maximoDownloads }}</td>
              <td>{{ row.item.tempoMaximo }}</td>


              <td class="d-flex align-center justify-end pa-0">
                <v-divider vertical></v-divider>
                <VisualizarParametro :parametro="row.item" />
                <v-divider vertical></v-divider>

                <EditarParametro :parametro="row.item" />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VisualizarParametro from "@/components/Modals/Parametro/VisualizarParametro.vue";
import EditarParametro from "@/components/Modals/Parametro/EditarParametro.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { VisualizarParametro, EditarParametro },
  data() {
    return {
      loading: false,
      pagina: 1,
      itensPorPagina: 10,
      headers: [
        {
          text: "Downloads",
          align: "start",
          sortable: false,
          class:
            "amber--text text--darken-4 subtitle-2 font-weight-bold text-center",
        },
        {
          text: "Tempo máximo no portal",
          align: "start",
          sortable: false,
          class:
            "amber--text text--darken-4 subtitle-2 font-weight-bold text-center",
        },
        {
          text: "Ações",
          align: "end",
          value: "actions",
          sortable: false,
          class:
            "amber--text text--darken-4 subtitle-2 font-weight-bold text-center",
        },
      ],
      footerProps: {
        itemsPerPageText: "Linhas por página",
        itemsPerPageAllText: "Tudo",
        pageText: "{0}-{1} de {2}",
        class:
          "amber--text text--darken-4 subtitle-4 font-weight-light text-center",
      },
      headerProps: {
        mobile: true,
        checkboxColor: "success--text",
      },
      items: [
        {
          name: "Teste",
          calories: 100,
          downloads: 300,
          tempo_asset_portal: 1460,
          path: "\\cmpacedsr006\\PoupaTempo\\IN",
        },
      ],
    };
  },
  async created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getParametros: "parametros/getParametros",
    }),
    async fetchData() {
      try {
        await this.getParametros();
      } catch (error) {
        console.log(error);
      }
    },
    async handlePageChange() {
      await this.fetchData();
    },
  },
  // methods: {},
  computed: {
    ...mapGetters({
      parametros: "parametros/getterParametros",
    }),
    totalPages() {
      let pages = null;
      if (this.parametros) {
        pages = Math.ceil(this.parametros.length / this.itensPorPagina);
      }
      return pages;
    },
  },
};
</script>