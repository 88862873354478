<template>
  <v-dialog v-model="dialog" width="630" min-height="600" style="background: var(--v-secondary-base)" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="text_action--text" color="primary" v-bind="attrs" v-on="on">
        ADICIONAR CONTEÚDO
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text style="height: 600px" class="text-center d-flex flex-column justify-center align-center"
        v-if="!form_wizard">
        <div class="mb-5">
          <v-btn disabled fab x-large style="height: 120px; width: 120px">
            <v-icon color="button_color">mdi-upload</v-icon>
          </v-btn>
        </div>

        <span class="mb-5">Seu vídeo ficará privado até que você publique.</span>
        <div v-if="!loading" class="inputControl">
          <label class="text_action--text" for="inputVideo">
            <strong> SELECIONAR ARQUIVO </strong>
          </label>

          <input id="inputVideo" type="file" @change="uploadToGcp" name="videos" />
        </div>

        <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
      </v-card-text>

      <v-card-text v-else>
        <v-row>
          <v-col>
            <video width="580" height="240" controls ref="video">
              <source :src="url" />
            </video>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <TagsCombobox v-model="tagsInput" :items="tags" @change="updateTagsObject" :editModel="null" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h2>Categorias</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-expansion-panels v-if="categoria_subcategoria">
              <v-expansion-panel v-for="categoria in categoria_subcategoria" :key="categoria.id">
                <v-expansion-panel-header color="input_color">{{ categoria.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content color="input_color">
                  <v-checkbox v-model="categoriasList" v-for="subcategoria in categoria.subcategorias"
                    :key="subcategoria.id_subcategoria" :label="subcategoria.name" :value="subcategoria" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-menu class="date_picker" v-model="modal_data_inicial" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto" color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field filled v-model="data_inicial_formatada" label="Período máximo de armazenamento do vídeo"
                  append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="primary"
                  background-color="gray4"></v-text-field>
              </template>
              <v-date-picker v-model="data_inicial" @input="modal_data_inicial = false" color="primary" locale="pt-Br">
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field background-color="input_color" filled label="Quantidade máx de downloads do vídeo"
              color="primary" v-model="maxDownloads" type="number">
            </v-text-field></v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="thumbWrapper" ref="thumbWrapper" @mousewheel="sideScroll">
              <v-img v-for="thumbnail in thumbnails" :key="thumbnail.idThumbnail" height="100% "
                :src="thumbnail.urlSigned" width="150px">
                <input type="checkbox" @click="(e) => onClick(e, thumbnail.idThumbnail)" class="imgCheckBox"
                  :id="thumbnail.idThumbnail" />
              </v-img>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center justify-end">
            <v-btn color="#FFFFFF" @click.prevent="closeDialog" text>
              CANCELAR
            </v-btn>
            <v-btn :loading="loading" color="primary" @click.prevent="inserirConteudo" class="text_action--text">
              <strong> SALVAR </strong>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

 
<script>
import axios from "axios";
import { apiClient, apiClientConversao } from "@/services/api";
import TagsCombobox from "./TagsCombobox.vue";
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import { convertSecondsToHHMMSS } from "@/utils/convertSecondsToHHMMSS";
export default {
  components: { TagsCombobox },
  data() {
    return {
      maxDownloads: 0,
      dialog: false,
      categorias_id: null,
      idUsuario: null,
      tagsInput: null,
      subcategorias_id: null,
      status_id: null,
      file: null,
      url: null,
      tagsObj: [],
      categoriasList: [],
      loading: false,
      form_wizard: false,
      conteudo_form_data: null,
      thumbnails: [],
      model: 0,
      thumbnail_id: null,
      checked: false,
      categoriasTreeview: null,
      data_inicial: null,
      data_inicial_formatada: null,
      modal_data_inicial: false,
    };
  },
  async created() {
    this.idUsuario = this.$store.state.users.user.id;
    await this.getCategorias({
      nome: "",
      pagina: 1,
      itensPorPagina: 1000,
    });
    await this.getTags({
      nome: "",
      pagina: 1,
      itensPorPagina: 100,
    });
    await this.getSubCategorias({
      categoria_id: this.categorias_id,
      nome: "",
      pagina: 1,
      itensPorPagina: 1000,
    });
  },
  computed: {
    ...mapGetters({
      categorias: "categorias/getterCategorias",
      subcategorias: "subcategorias/getterSubcategorias",
      tags: "tags/getterTags",
      categoria_subcategoria: "categorias/getterCategoriasSubcategorias",
      filtros: "conteudos/getterFiltrosConteudo",
    }),
  },
  watch: {
    async tagsInput() {
      await this.getTags({
        nome: this.tagsInput || "",
        pagina: 1,
        itensPorPagina: 10,
      });
    },
    data_inicial(val) {
      this.data_inicial_formatada = dayjs(val).format("DD/MM/YYYY");
    },
    data_final(val) {
      this.data_final_formatada = dayjs(val).format("DD/MM/YYYY");
    },
  },
  methods: {
    ...mapActions({
      getCategorias: "categorias/getCategorias",
      getSubCategorias: "subcategorias/getSubCategorias",
      getTags: "tags/getTags",
      getConteudos: "conteudos/getConteudos",
    }),
    updateTagsObject(value) {
      this.tagsObj = value;
    },
    sideScroll(e) {
      this.$refs.thumbWrapper.scrollLeft += e.deltaY;
    },
    onClick(e, thumbnail_id) {
      this.thumbnail_id = thumbnail_id;
      if (e.target.checked === true) {
        const elements = document.getElementsByClassName("imgCheckBox");
        for (let i = 0; i <= elements.length - 1; i++) {
          if (elements[i].id !== e.target.id) {
            elements[i].checked = false;
          }
        }
        return;
      }
    },

    async closeDialog() {
      this.dialog = false;
      this.categorias_id = null;
      this.subcategorias_id = null;
      this.status_id = null;
      this.file = null;
      this.url = null;
      this.form_wizard = null;
      this.maxDownloads = null;
      this.data_inicial = null;
      this.data_inicial_formatada = null;
      this.categoriasList = [];
      if (this.$refs.video) {
        this.$refs.video.pause();
        this.$refs.video.currentTime = 0;
      }
    },
    async checkVideoConvertido(videoId, timeout) {
      let canceltimeout = timeout;
      const checkVideoConvertido = await apiClientConversao.post(
        `/video/verificaconversao?videoId=${videoId}`
      );

      if (!checkVideoConvertido.data) {
        canceltimeout = setTimeout(async () => {
          await this.checkVideoConvertido(videoId, canceltimeout);
        }, 10000);
      } else {
        clearTimeout(canceltimeout);

        const response = await apiClientConversao.get(
          `/video/getsignedurlvisualizacao?idVideo=${this.conteudo_form_data.id}`
        );
        const thumb_response = await apiClientConversao.get(
          `/video/getsignedurlthumb?idVideo=${this.conteudo_form_data.id}`
        );

        if (response.status === 200 && thumb_response.status === 200) {
          console.log(response);
          this.url = response.data;
          this.loading = false;
          this.form_wizard = true;
          this.thumbnails = thumb_response.data;
          console.log(this.thumbnails);
        }

        return true;
      }
    },
    async uploadToGcp(e) {
      if (
        e.target.files[0].type !== "" &&
        e.target.files[0].type !== "video/mp4"
      ) {
        return this.$toast.error("Formato do arquivo não suportado");
      }

      this.file = e.target.files[0];
      this.loading = true;
      let responseConversao;

      await apiClientConversao
        .get(
          `/video/getsignedurlupload?nomeArquivo=${this.file.name}&idUsuario=${this.$store.state.users.user.id}&pasta=50MBits/`
        )
        .then(async (response) => {
          responseConversao = response;
          await axios
            .post(
              `${response.data.urlSigned}`,
              {
                file: this.file,
              },
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            )
            .then(async () => {
              await apiClientConversao
                .post(`/video`, {
                  arquivoNome: responseConversao.data.arquivoNome,
                  guid: responseConversao.data.guid,
                  idUsuario: responseConversao.data.criadoPor,
                  urlSigned: responseConversao.data.urlSigned,
                })
                .then(async (response) => {
                  this.conteudo_form_data = response.data;
                  await this.checkVideoConvertido(response.data.id);
                });
            })
            .catch((error) => {
              console.error("Erro ao fazer upload gcp", error);
            });
        })
        .catch((error) => {
          console.error("Erro ao converter vídeo", error);
        });
    },
    async inserirConteudo() {
      try {
        if (!this.maxDownloads) {
          return this.$toast.error("Insira o número máximo de downloads!");
        }

        if (!this.thumbnail_id) {
          return this.$toast.error("Selecione uma thumbnail!");
        }

        if (this.categoriasList.length < 0) {
          return this.$toast.error("Selecione uma categoria/subcategoria!");
        }

        this.loading = true;

        const duration = convertSecondsToHHMMSS(this.$refs.video.duration);

        const response = await apiClient.post("/conteudo", {
          status: 1,
          criadoPor: this.$store.state.users.user.id,
          criadoEm: new Date(),
          pracaId: null,
          videoId: this.conteudo_form_data.id,
          thumbId: this.thumbnail_id,
          alteradoPor: this.$store.state.users.user.id,
          alteradoEm: new Date(),
          maxDownload: this.maxDownloads,
          limiteArmazenamento: new Date(this.data_inicial),
          duracao: duration,
          nrVisualizacao: 0,
          nrDownload: 0,
        });

        if (response.status === 200) {
          let payload_categoria = [];
          let payload_tag = [];

          this.categoriasList.map((categoria) => {
            const data = {
              conteudoId: response.data.id,
              categoriaId: categoria.id_categoria,
              subcategoriaId: categoria.id_subcategoria,
            };

            payload_categoria.push(data);
          });

          this.tagsObj.map((tag) => {
            const data = {
              alteradoEm: tag.alteradoEm,
              alteradoPor: tag.alteradoPor,
              criadoEm: tag.criadoEm,
              criadoPor: tag.criadoPor,
              id: tag.id,
              idConteudo: response.data.id,
              nome: tag.nome,
              status: 1,
            };

            payload_tag.push(data);
          });

          await this.inserirCategoriaSubCategoria(payload_categoria);
          await this.inserirTags(payload_tag);

          await this.getConteudos({
            tag: this.filtros.tag,
            categoria: this.filtros.categoria_id,
            subcategoria: this.filtros.subcategoria_id,
            status: this.filtros.status,
            praca: this.filtros.praca_id,
            pagina: this.filtros.pagina,
            itensPorPagina: this.filtros.itensPorPagina,
          });

          await this.closeDialog();
        }
      } catch (error) {
        return this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    },

    async inserirCategoriaSubCategoria(payload) {
      try {
        await apiClient.post(
          "/conteudo/createconteudocategoriasubcateria",
          payload
        );
      } catch (error) {
        console.log(error);
      }
    },

    async inserirTags(payload) {
      try {
        await apiClient.post("/tag", payload);
      } catch (error) {
        console.log(error);
      }
    },

    generateVideoThumbnail(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        // this is important
        video.autoplay = true;
        video.muted = true;
        video.src = URL.createObjectURL(file);

        video.onloadeddata = () => {
          let ctx = canvas.getContext("2d");

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          video.pause();
          return resolve(canvas.toDataURL("image/png"));
        };
      });
    },

    async createBlob(base64) {
      let res = await fetch(base64);
      let myBlob = await res.blob();

      return myBlob;
    },
  },
};
</script>

<style lang="scss" scoped>
.inputControl {
  background-color: var(--v-primary-base);
  width: 240px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;

  label {
    color: #ffffff;
  }

  input {
    display: none;
  }
}

.thumbWrapper {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  min-width: 100px;
  gap: 0.5rem;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--v-surface_neutral_selected-base);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--v-text_secondary-base);
}
</style>