<template>
  <div class="text-center">
    <v-dialog v-model="removerDialog" width="500" height="180">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <v-card class="card">
        <v-card-title>
          Excluir video
          <v-spacer></v-spacer>
          <v-btn icon @click="removerDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="cardText">
          <p>Tem certeza que deseja excluir esse vídeo?</p>
          <div class="buttonsWrapper">
            <v-btn
              @click.prevent="removerDialog = false"
              class="mx-4 cancelarButton"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              :loading="loading"
              class="excluirButton"
              @click.prevent="removerConteudo(id)"
            >
              Excluir
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

 
<script>
import { apiClient } from "@/services/api";
import { mapActions } from "vuex";
export default {
  props: {
    id: Number,
  },
  data() {
    return {
      removerDialog: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      getConteudos: "conteudos/getConteudos",
    }),
    async removerConteudo(id) {
      try {
        this.loading = true;
        const response = await apiClient.delete(`conteudo/${id}`);

        if (response.status === 200) {
          await this.getConteudos({
            tag: this.$store.state.conteudos.filtros.tag,
            categoria: this.$store.state.conteudos.filtros.categoria_id,
            subcategoria: this.$store.state.conteudos.filtros.subcategoria_id,
            status: this.$store.state.conteudos.filtros.status,
            praca: this.$store.state.conteudos.filtros.praca_id,
            pagina: this.$store.state.conteudos.filtros.pagina,
            itensPorPagina: this.$store.state.conteudos.filtros.itensPorPagina,
          });
        }
      } catch (error) {
        this.$toast.error("Não foi possível remover conteúdo");
      } finally {
        this.loading = false;
        this.removerDialog = false;
      }
    },
    handleExcluir() {},
  },
};
</script>

<style lang="scss" scoped>
.cardText {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--v-gray3-base);
  }
  p + p {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: var(--v-gray1-base);
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .v-btn {
    width: 110px;
    height: 36px;
  }
}

.excluirButton {
  background: var(--v-error-base) !important;
  color: var(--v-gray4-base);
  span {
    text-transform: uppercase !important;
  }
}

.cancelarButton {
  background: transparent;
}
</style>